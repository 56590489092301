/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const SubgrupoSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.addOrder('subgrupos.descricao', 'asc')
  filtro.fields = ['subgrupos.descricao', 'grupos.descricao as grupo_desc', 'subgrupos.bloqueado', 'subgrupos.grupo_id']
  filtro.join.addLeft('grupos','grupos.grupo_id','subgrupos.grupo_id')
  return filtro
}
