/* eslint-disable */
import {ClientePf} from './cliente_pf'
import {ClientePj} from './cliente_pj'
import {ClienteEndereco} from "./cliente_endereco";
import { v4 as uuidV4 } from 'uuid';
export class Cliente {
  constructor() {

    this.cliente_id = uuidV4()
    this.nome = ''
    this.tp_pessoa = ''
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.password = ''
    this.bloqueado = false
    this.cliente_pf = new ClientePf(this.cliente_id)
    this.cliente_pj = new ClientePj(this.cliente_id)
    this.cliente_enderecos = []
    this.vl_cashback = '0,00'
    this.paroquia_id = ''
  }
}
