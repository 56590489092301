<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-navigation-drawer width="260px" left class="testeHover" id="sidebar" ref="sideDrawer" stateless value="true" dark
                         persistent :mini-variant="miniVariant" :clipped="clipped" v-model="drawer"
                         enable-resize-watchers app>
      <v-list>
        <v-list-item>
          <v-list-item-action @click.stop="abreFechaSidebar()" id="icnClick2">
            <v-btn text icon>
              <v-icon class="listHoverIcon">mdi-format-align-justify</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-title class="title">{{$route.meta}}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list style="background: transparent" id="listHover" class="listHover">
        <v-list>
          <v-list-item to="/principal" @click="irPara('principal')">
            <v-list-item-action>
              <v-icon class="listHoverIcon">mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-title class="navmenu" style="font-size: 1.2rem; ">HOME</v-list-item-title>
          </v-list-item>
        </v-list>

        <!--CADASTRO-->
        <v-list-group prepend-icon="mdi-view-list" no-action dark>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Cadastros</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="(cadastro, index) in cadastros" :key="'cadastro' + index"  :to="cadastro.path"
                       @click="irPara(cadastro.path)">
            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">
              <v-list-item-title>
                {{ cadastro.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="cadastro.path">{{ cadastro.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--MOVIMENTACAO-->
        <v-list-group prepend-icon="mdi-swap-horizontal" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Movimentação</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="(mov, index) in movimentacao" :key="'mov'+index" :to="mov.path"
                       @click="irPara(mov.path)">
            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">
              <v-list-item-title>
                {{ mov.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="mov.path">{{ mov.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--RELATORIOS-->
        <v-list-group prepend-icon="mdi-currency-usd" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Relatórios</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="relatorio in relatorios" :key="relatorio.title" :to="relatorio.path"
                       @click="irPara(relatorio.path)">
            <v-card class="dropnav" flat style="background: transparent" v-show="miniVariant">
              <v-list-item-title>
                {{ relatorio.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="relatorio.path">{{ relatorio.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--CONFIGURAÇÕES-->
        <v-list-group prepend-icon="mdi-cog" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Configuração</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="configuracao in configuracao" :key="configuracao.title"
                       @click="irPara(configuracao.path)" :to="configuracao.path">

            <v-card class="dropnav" flat style="background: transparent"
                    v-show="miniVariant">
              <v-list-item-title>
                {{ configuracao.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="configuracao.path">{{ configuracao.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--FRONTEND-->
        <v-list-group prepend-icon="mdi-view-carousel-outline" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title style="text-transform: uppercase">Frontend</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="navmenu" v-for="front in frontend" :key="front.title"
                       @click="irPara(front.path)" :to="front.path">

            <v-card class="dropnav" flat style="background: transparent"
                    v-show="miniVariant">
              <v-list-item-title>
                {{ front.title.substr(0, 3).toUpperCase() }}
              </v-list-item-title>
            </v-card>

            <v-list-item-content>
              <v-list-item-title :id="front.path">{{ front.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list style="text-align: left">
          <v-list-item @click="sair()">
            <v-list-item-action>
              <v-icon class="listHoverIcon">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-title class="navmenu">Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar id="toobarDash" :clipped-left="clipped" app dark>
      <v-app-bar-nav-icon v-show="bntTop" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-show="bntTop">{{$route.meta}}</v-toolbar-title>
      <!--SEARCH-->
      <div style="width: 50%" v-show="toolbarsearch">
        <!--<v-text-field  dense :value="search" @change="v => search = v" flat solo-inverted prepend-inner-icon="search"-->
                      <!--label="Pesquisar" class="hidden-sm-and-down inp " id="pesquisando"-->
                       <!--v-on:keyup="PressEnter()"-->
        <!--&gt;</v-text-field>-->
<!-- @change="Pesquisar()" -->
        <v-text-field
          v-model="model"
          :search-input.sync="pesquisa"
          label="Pesquisar"
          @keyup="Pesquisar"
          class="hidden-sm-and-down inp "
          flat solo-inverted
          prepend-inner-icon="mdi-magnify"
          :append-icon="showFilter ? 'fa fa-filter' : ''" @click:append="abreModalFiltro()"
        ></v-text-field>

      </div>
      <v-spacer></v-spacer>

      <token-expiration-timer/>
      <div v-if="btnPrint">
        <v-btn small dark fab color="blue darken-2" @click="executaPrintBtn">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </div>

      <v-btn dark icon v-show="toolbarsave" @click="executaCancelBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn dark icon v-show="toolbarsave" :loading="show_progress" @click="executaSaveBtn">
        <v-icon>mdi-check</v-icon>
      </v-btn>

      <v-app-bar-nav-icon @click.stop="trocaIncon(drawerRight)" v-show="!drawerRight">
        <v-icon>{{icon}}</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <!-- Parte que exibir o conteudo central do cadastro -->
    <v-main fluid id="conteudo">
      <v-container fluid style="position: relative !important;">
        <router-view/>
        <br>
        <div class="text-align-rigth" style="color: white">
          <!--tempo em minutos-->
          <pro-timer tempo="1440" texto="Sua sessão expira em: "></pro-timer>
        </div>
      </v-container>
    </v-main>

    <v-navigation-drawer :clipped="clipped" clipped-right dark fixed v-model="drawerRight" right enable-resize-watchers
                         stateless persistent app id="sidebarRight" width="60px" v-if="btnAdd">
      <v-list style="background: transparent" class="drawbtn">
        <v-btn  icon @click.stop="trocaIncon(drawerRight)">
          <v-icon>{{icon}}</v-icon>
        </v-btn>
      </v-list>

      <div class="printbtn" v-show="btnPrint">
        <v-btn small fab color="blue darken-2" @click="executaPrintBtn">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </div>

      <div class="drawrightbtn" v-show="btnAdd">
        <v-btn small fab color="green darken-2" @click="executaAddBtn">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <!-- Renovar Login -->
    <renovar-login/>

    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top color="success" :timeout="2000">
      <div class="d-flex justify-space-between align-center">
        {{ msgSuccess }}
        <v-btn small color="white" text @click="toggleSnackSuccess">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>

  </div>
</template>

<script async>
/* eslint-disable */
  import util from '../assets/js/Util'
  import {mapState, mapActions, mapGetters} from 'vuex'
  import {EventBus} from '../helpers/event-bus'
  import router from '../router'
  import miscImageCropper from "../helpers/img-cropper";
  import miscMixin from "../helpers/misc";

  export default {
    mixins: [miscMixin, miscImageCropper],
    components: {
      ProTimer: () => import('../components/Temporizador.vue'),
      RenovarLogin: () => import('../views/RenovarLogin.vue'),
    },
    data() {
      return {
        pesquisa: null,
        model: null,
        bntTop: false,
        show_progress: false,
        drawerRight: true,
        icon: 'keyboard_arrow_left',
        clipped: false,
        drawer: true,
        fixed: false,
        principal: [{path: '/principal', title: 'Principal', icon: 'home'}],
        frontend: [{path: '/banner', title: 'Banner', icon: 'home'}],
        movimentacao: [
          {path: '/movimentacao/pedido', title: 'Pedidos', icon: 'home'},
          {path: '/movimentacao/requisicao', title: 'Requisições', icon: 'home'},
          {path: '/movimentacao/romaneio', title: 'Romaneios', icon: 'home'},
          ],
        relatorios: [
          {path: '/relatorio/listagem_produto', title: 'Listagem de Produtos', icon: 'home'},
          {path: '/relatorio/extrato_cashback', title: 'Extrato de Cashback', icon: 'home'},
          // {path: '', title: 'Ranking de Vendas', icon: 'home'},
          // {path: '/', title: 'Pontuação de Clientes', icon: 'home'},
        ],
        configuracao: [
          {path: '/empresa', title: 'Empresa', icon: 'home'},
          {path: '/tema', title: 'Tema', icon: 'home'},
          {path: '/termo', title: 'Termo', icon: 'home'},
          {path: '/sobre', title: 'Sobre', icon: 'add_shopping_cart'},
        ],
        cadastros: [
          {path: '/produtos', title: 'Produtos', icon: 'home'},
          {path: '/departamentos', title: 'Departamentos', icon: 'home'},
          {path: '/subgrupo', title: 'Subdepartamentos', icon: 'home'},
          {path: '/marcas', title: 'Marcas', icon: 'home'},
          {path: '/clientes', title: 'Clientes', icon: 'home'},
          {path: '/fornecedores', title: 'Fornecedores', icon: 'home'},
          {path: '/diocese', title: 'Dioceses', icon: 'home'},
          {path: '/municipio', title: 'Municípios', icon: 'home'},
          {path: '/paroquia', title: 'Paróquias', icon: 'home'},
          {path: '/rotas', title: 'Rotas', icon: 'home'},
          {path: '/usuario', title: 'Usuários', icon: 'home'},
          {path: '/notificacao', title: 'Notificação', icon: 'home'},
        ],
        miniVariant2: false,
        right: null
      }
    },
    name: 'Dash',
    methods: {
      ...mapActions('municipio', ['setAllListMunicipios']),
      // ...mapActions('empresa', ['setAllEmpresas']),
      ...mapActions('configuracao', ['setAllTemaByUser']),
      // ...mapActions('bairro', ['setAllBairros']),
      // ...mapActions('fornecedor', ['setAllFornecedores']),
      ...mapActions('geral', ['setMiniVariant', 'setShowFilter', 'toggleMiniVariant', 'setSidebarColor', 'setSidebarID', 'setModalFiltro',
        'setModal', 'toggleModalFiltro', 'setSearch', 'toggleDialogError', 'toggleSnackSuccess', 'setShowRenovarLoginMsg', 'setRenovarLogin']),
      ...mapActions('token', ['start', 'cancelarToken']),
      sair() {
        this.cancelarToken()
        sessionStorage.clear()
        window.location = '/'
        // router.push({path: '/'}).catch(err => {})
      },
      irPara(id) {
        this.setSearch('')
        this.model = ''
        this.setSidebarID(id)
        if (id === '/produtos' || id === '/paroquia' || id === '/movimentacao/pedido') {
          this.setShowFilter(true)
        }else {
          this.setShowFilter(false)
        }

        let tinycolor = require('tinycolor2')
        let color
        let c
        if (util.validaCampo(this.sidebarColor)) {
          if (this.sidebarColor === '#000000') {
            c = '#1d94ff'
          } else {
            color = this.sidebarColor
            var colors = tinycolor(color).triad()
            colors = colors.map(function (t) {
              return t.toHexString()
            })
            c = colors[1]
            c = tinycolor(c).darken().toString()
          }
        } else {
          c = '#1d94ff'
        }

        let teste = document.getElementsByClassName('testeHover')
        let listtile = teste[0].getElementsByTagName('div')
        for (let i = 0; i < listtile.length; i++) {
          if (util.validaCampo(listtile[i].id)) {
            if (listtile[i].id.toString() !== id.toString()) {
              listtile[i].style.color = 'white'
            } else {
              listtile[i].style.color = c
            }
          }
        }
      },
      cadastrar() {
        this.setModal(true)
      },
      trocaIncon(drawerRight) {
        this.drawerRight = !this.drawerRight
        if (drawerRight) {
          this.icon = 'keyboard_arrow_right'
        } else {
          this.icon = 'keyboard_arrow_left'
        }
      },
      abreModalFiltro() {
        this.toggleModalFiltro()

        const sectionQuadra = document.getElementById('sectionQuadra')
        const sectionLote = document.getElementById('sectionLote')
        const sectionEventoCaixa = document.getElementById('sectionEventoCaixa')

        if (sectionQuadra !== null) {
          sectionQuadra.style.animation = ''
          if (this.modalfiltro) {
            sectionQuadra.style.animation = 'fadeInDown2 0.5s linear'
          }
        }

        if (sectionLote !== null) {
          sectionLote.style.animation = ''
          if (this.modalfiltro) {
            sectionLote.style.animation = 'fadeInDown2 0.5s linear'
          }
        }
        if (sectionEventoCaixa !== null) {
          sectionEventoCaixa.style.animation = ''
          if (this.modalfiltro) {
            sectionEventoCaixa.style.animation = 'fadeInDown2 0.5s linear'
          }
        }
      },
      abreFechaSidebar() {
        this.toggleMiniVariant()
      },
      executaAddBtn(event) {
        EventBus.$emit('CLICK_BTN_ADD')
      },
      executaPrintBtn(event) {
        EventBus.$emit('CLICK_BTN_PRINT')
      },
      executaSaveBtn(event) {
        this.show_progress = true
        EventBus.$emit('CLICK_BTN_SAVE')
      },
      executaCancelBtn(event) {
        this.show_progress = false
        EventBus.$emit('CLICK_BTN_CANCEL')
      },
      setBacground() {
        var url = ''
        var cont = 0
        if (this.validarCampo(this.tema)) {
          if (this.tema.urls_imagem.length > 0) {
            this.tema.urls_imagem.forEach(i => {
              if (i.capa) {
                cont++
              }
            })
            if (cont > 0) {
              url = this.selecionaImagem(this.tema.urls_imagem, '1600')
            }
          }
        } else {
          url = "https://picsum.photos/1600/900?random.jpg"
        }
        return url
      },
      Pesquisar(event) {
        if (event.keyCode === 13) { // ENTER
          this.setSearch(this.model)
        }
      },
    },
    watch: {
      search: function () {
        // console.log('search: ',this.search);
        this.model = this.search
      },
      'model': function () {
        if (!this.validarCampo(this.model)) {
          this.setSearch("")
        }
      },
      pesquisa: function (val) {
        if (this.validarCampo(val)) {
          this.setSearch(val)
        }
      },
    },
    created(){
      this.setAllListMunicipios()
    },
    mounted() {
      if(!this.iniciado) this.start()

      // Plano de fundo
      var _this =this
      var inspireInicio = document.getElementById('inspireInicio')

      inspireInicio.style.backgroundRepeat = 'no-repeat'
      inspireInicio.style.backgroundAttachment = 'fixed'
      inspireInicio.style.backgroundSize = '100% 100%'

      if (sessionStorage.getItem("usuario_id") !== null) {
        if (this.tema === null) {
          this.setAllTemaByUser({id: sessionStorage.getItem("usuario_id")})
          EventBus.$on('TEMA_CONCLUIDO', (sucesso) => {
            if (sucesso) {
              this.nova_configuracao(this.tema)
              inspireInicio.style.backgroundImage = "url(" + this.setBacground() + ")"
            }
          })
        } else {
          inspireInicio.style.backgroundImage = "url(" + this.setBacground() + ")"
          this.nova_configuracao(this.tema)
        }
      } else {
        router.push({path: '/'}).catch(err => {
        })
      }

      function func() {
        if (_this.miniVariant2) {
          _this.setMiniVariant(false)
        }
      }

      function func1() {
        if (_this.miniVariant2) {
          _this.setMiniVariant(true)
        }
      }

      let item = document.getElementById('sidebar')
      let icnClick2 = document.getElementById('icnClick2')
      icnClick2.addEventListener('click', function (event) {
        if (!_this.miniVariant2) {
          _this.miniVariant2 = true
          item.addEventListener('mouseover', func, false)
          item.addEventListener('mouseout', func1, false)
        } else {
          _this.miniVariant2 = false
          _this.setMiniVariant(false)
        }
      }, false)

      EventBus.$on('PROCESSO_CONCLUIDO', (sucesso, fecha, path) => {
        this.show_progress = false
        if (sucesso && fecha) {
          router.push({name: path}).catch(err => {
          })
        }
      })

      EventBus.$on('RETORNO_PRINCIPAL', (path) => {
        this.show_progress = false
        router.push({name: 'Principal'}).catch(err => {})
        document.getElementById(path).style.color = 'white'
      })

      EventBus.$on('RENOVAR_LOGIN', (renovar) => {
      if(this.renovarLoginModal) return

      if(renovar){
        this.setShowRenovarLoginMsg(false)
        this.setRenovarLogin(true)
      }
    })
    },
    computed: {
      ...mapGetters('geral', {search: 'getSearch'}),
      ...mapGetters('configuracao', {tema: 'pegarTema'}),
      ...mapState('token', { iniciado: 'iniciado' }),
      ...mapState('geral', {
        toolbarsave: 'toolbarsave',
        toolbarsearch: 'toolbarsearch',
        btnAdd: 'btnAdd',
        btnPrint: 'btnPrint',
        miniVariant: 'miniVariant',
        sidebarColor: 'sidebarColor',
        modalFiltro: 'modalFiltro',
        toolbarDash: 'toolbarDash',
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        showFilter: 'showFilter',
        msgSuccess: 'msgSuccess',
        renovarLoginModal: 'renovarLoginModal'
      }),
      showSnack: {
        get() {
          return this.snackSuccess
        },
        set() {
          this.toggleSnackSuccess()
        }
      }
    },
    beforeDestroy () {
      EventBus.$off('CLICK_BTN_ADD')
      EventBus.$off('CLICK_BTN_PRINT')
      EventBus.$off('TEMA_CONCLUIDO')
      EventBus.$off('CLICK_BTN_SAVE')
      EventBus.$off('CLICK_BTN_CANCEL')
      EventBus.$off('PROCESSO_CONCLUIDO')
      EventBus.$off('RETORNO_PRINCIPAL')
    }
  }
</script>
