/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const ParoquiaSCH = (perPage) => {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['paroquias.*', 'municipios.descricao as municipio']
    filtro.join.addLeft('municipios', 'municipios.municipio_id', 'paroquias.municipio_id')
    filtro.addOrder('paroquias.razao', 'asc')
    return filtro
}
