/* eslint-disable */

import {Filtro} from "../models/filtro/filtro";

export const  getBaseSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  return filtro
}

export const BaseListSCH = (perPage, field)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = field

  return filtro
}

