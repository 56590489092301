import {geraUrlRelatorio} from '../../../services/GenericAPI'
import {Filtro} from '../../../models/filtro/filtro'

const url = '/relatorios'

const state = {
  filtro: new Filtro(),
  pdf: ''
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async gerarRaletorio({commit}, {relatorio, onSucesso, onErro}) {
    try {
      const response = (await geraUrlRelatorio(url + '/pdf/' + relatorio, state.filtro))
      commit('SET_PDF', response)
      onSucesso(response)
    } catch (error) {
      onErro()
    }
  }
}

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
