<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="timer" >
    <span style="font-size: 12px"> {{montaTexto()}} </span>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span v-if="pegaTimeCount <= 300000" class="renovar" @click="renovarLogin()" v-bind="attrs" v-on="on">
          <v-icon>mdi-reload</v-icon>
        </span>
      </template>
      <span>Renovar Sessão</span>
    </v-tooltip>

    <span style="margin-right: 14px;"></span>
  </div>
</template>

<script async>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'TokenExpirationTimer',
  methods: {
    ...mapActions('geral', ['setRenovarLogin', 'setShowRenovarLoginMsg', 'setReloadPageRenovarLogin']),
    montaTexto() {
      if (this.pegaTimeCount === 0) {
        return 'Sua sessão expirou! Faça o login novamente.'
      }
      let ss = Math.floor((this.pegaTimeCount / 1000) % 60)
      let mm = Math.floor((this.pegaTimeCount / (1000 * 60)) % 60)
      let hh = Math.floor((this.pegaTimeCount / (1000 * 60 * 60)) % 24)

      hh = (hh < 10) ? '0' + hh : hh
      mm = (mm < 10) ? '0' + mm : mm
      ss = (ss < 10) ? '0' + ss : ss

      return 'Sua sessão expira em: ' + hh + ':' + mm + ':' + ss
    },

    renovarLogin() {
      if (this.pegaTimeCount > 0) {
        this.setShowRenovarLoginMsg(false)
        this.setReloadPageRenovarLogin(false)
      }
      this.setRenovarLogin(true)
    }
  },
  computed: {
    ...mapGetters('token', ['pegaTimeCount'])
  }
}
</script>

<style scoped>
  span.renovar{
    padding: 6px;
    border-radius: 36px;
  }
  span.renovar:hover{
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
</style>
