/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Requisicao {
  constructor () {
    this.requisicao_id = uuidV4()
    this.fornecedor_id = ''
    this.produto_id = ''
    this.tp_mov = ''
    this.qtd_mov = ''
    this.pr_custo = ''
  }
}
