/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from './views/Dash.vue'
import Login from './views/Login.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/admin',
      redirect: '/admin',
      // meta: { requiresAuth: true },
      name: 'home',
      component: Dash,
      meta: 'Admin',
      children: [
        // PRINCIPAL
        {
          path: '/principal',
          name: 'Principal',
          meta: 'Principal',
          component: () => import(/* webpackChunkName: "about" */ './views/Home/Home.vue')
        },
        // CADASTROS
        // CLIENTE
        {
          path: '/clientes',
          name: 'Clientes',
          meta: 'Clientes',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/cliente/ClienteLista.vue')
        },
        {
          path: '/clientes/cadastrar',
          name: 'ClienteCadastrar',
          meta: 'Clientes',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/cliente/ClienteCadastro.vue')
        },
        // FORNECEDORES
        {
          path: '/fornecedores',
          name: 'Fornecedores',
          meta: 'Fornecedor',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/fornecedores/FornecedoresLista.vue')
        },
        // PRODUTOS
        {
          path: '/produtos',
          name: 'Produtos',
          meta: 'Produtos',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/produtos/ProdutosLista.vue')
        },
        {
          path: '/produtos/cadastrar',
          name: 'ProdutosCadastrar',
          meta: 'Produtos',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/produtos/ProdutosCadastrar.vue')
        },

        // DEPARTAMENTOS
        {
          path: '/departamentos',
          name: 'Departamento',
          meta: 'Departamento',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/departamento/Departamento.vue')
        },
        // SUBGRUPOS
        {
          path: '/subgrupo',
          name: 'Subdepartamentos',
          meta: 'Subdepartamentos',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/subgrupos/Subgrupos.vue')
        },

        // MUNICIPIO
        {
          path: '/municipio',
          name: 'Municipio',
          meta: 'Município',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/municipio/Municipio.vue')
        },
        {
          path: '/diocese',
          name: 'Dioceses',
          meta: 'Diocese',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/dioceses/Diocese.vue')
        },
        {
            path: '/paroquia',
            name: 'Paroquias',
            meta: 'Paróquia',
            component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/paroquia/Paroquia.vue')
          },
        {
          path: '/marcas',
          name: 'Marcas',
          meta: 'Marcas',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/marcas/Marca.vue')
        },
        {
          path: '/rotas',
          name: 'Rotas',
          meta: 'Rotas',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/rotas/RotaLista.vue')
        },
        {
          path: '/rotas/cadastro',
          name: 'RotasCadastro',
          meta: 'Rotas',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/rotas/RotaCadastro.vue')
        },

        // CONFIGURAÇÕES
        {
          path: '/sobre',
          name: 'Sobre',
          meta: 'Sobre',
          component: () => import(/* webpackChunkName: "about" */ './views/Configuracao/Sobre.vue')
        },
        {
          path: '/tema',
          name: 'Tema',
          meta: 'Tema',
          component: () => import(/* webpackChunkName: "about" */ './views/Configuracao/Tema/Tema.vue')
        },
        {
          path: '/termo',
          name: 'Termo',
          meta: 'Termo',
          component: () => import(/* webpackChunkName: "about" */ './views/Configuracao/termo/Termo.vue')
        },
        {
          path: '/empresa',
          name: 'Empresa',
          meta: 'Empresa',
          component: () => import(/* webpackChunkName: "about" */ './views/Configuracao/empresa/Empresa.vue')
        },
        {
          path: '/usuario',
          name: 'Usuário',
          meta: 'Usuário',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/usuarios/Usuario.vue')
        },
        {
          path: '/notificacao',
          name: 'Notificação',
          meta: 'Notificação',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/notificacao/Notificacao.vue')
        },
        // MOVIMENTAÇÃO
        {
          path: '/movimentacao/pedido',
          name: 'Pedidos',
          meta: 'Pedidos',
          component: () => import(/* webpackChunkName: "about" */ './views/Movimentacao/Pedido/Pedidos.vue')
        },

        {
          path: '/movimentacao/requisicao',
          name: 'Requisiçao',
          meta: 'Requisição',
          component: () => import(/* webpackChunkName: "about" */ './views/Movimentacao/Requisicao/Requisicao.vue')
        },
        {
          path: '/movimentacao/romaneio',
          name: 'Romaneios',
          meta: 'Romaneios',
          component: () => import(/* webpackChunkName: "about" */ './views/Movimentacao/romaneio/RomaneioLista.vue')
        },
        {
          path: '/movimentacao/romaneioCadastro',
          name: 'RomaneioCadastro',
          meta: 'Romaneios',
          component: () => import(/* webpackChunkName: "about" */ './views/Movimentacao/romaneio/RomaneioCadastro.vue')
        },
        {
          path: '/banner',
          name: 'BannerLista',
          meta: 'Banner',
          component: () => import(/* webpackChunkName: "about" */ './views/FrontEnd/banner/BannerLista.vue')
        },
        {
          path: '/banner/cadastrar',
          name: 'BannerCadastrar',
          meta: 'Banner',
          component: () => import(/* webpackChunkName: "about" */ './views/FrontEnd/banner/Banner.vue')
        },
        {
          path: '/relatorio/listagem_produto',
          name: 'ListagemProduto',
          meta: 'ListagemProduto',
          component: () => import(/* webpackChunkName: "about" */ './views/Relatorios/ListagemProduto.vue')
        },
        {
          path: '/relatorio/extrato_cashback',
          name: 'ExtratoCashback',
          meta: 'ExtratoCashback',
          component: () => import(/* webpackChunkName: "about" */ './views/Relatorios/ExtratoCashback.vue')
        },
      ]
    },
    // 404
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
    },
    {
      path: '/teste',
      name: 'tela',
      component: () => import(/* webpackChunkName: "about" */ './views/FrontEnd/banner/tela_inteira/TelaInteira.vue')
    }
  ]
})
