/* eslint-disable */
import {filtrando, lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH"
import {MarcaSCH} from "../../../search/MarcaSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Marca} from '../../../models/marca'

const url = '/marca'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro()
}

const getters = {
  listaAllMarcas: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  listaMarcas: (state) => {
    return state.all
  },
  listaMarcasSemFiltro: (state) => {
    return state.all
  },
  pegaMarcaId: (state) => (id) => {
    let mar = state.all.find(marca => marca.marca_id === id)
    return mar !== null ? mar.descricao : '** NÃO ENCONTRADA'
  },
  pegaMarca: (state) => (id) => {
    let mar = state.allLista.find(marca => marca.marca_id === id)
    return mar !== null ? mar : new Marca()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListMarca({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('MARCAS_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(
      ['&unaccent(descricao)'],
      "&'%' || unaccent('"+search+"') || '%'", 'ilike'
    )
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('marca_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('SET_MARCAS', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', MarcaSCH(perPage))
  },
  async filtarMarca({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_MARCAS', list2)
  },

  async setAllMarcas({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_MARCAS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async setAllMarcasDesbloqueada({commit}) {
    const list = (await lista(url + '/alldesbloqueada')).data

    commit('SET_ALL_LIST', list)
  },
  async processarMarca({commit}, {marca, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, marca, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_MARCAS(state, marcas) {
      state.all = marcas
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
