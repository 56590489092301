/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ClientePf {
  constructor (clienteId) {
    this.cliente_pf_id = uuidV4()
    this.cliente_id = clienteId
    this.cpf = ''
    this.dt_nascimento = null
    this.sexo = ''
  }
}
