<template>
  <div>
    <v-dialog v-model="Mostrar" :width="width" persistent>
      <v-card>
        <v-card-title class="headline" primary-title id="modalhead" :style="'background: '+setTemaColor()+'; color: white'">
          {{getTitulo()}}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script async>
/* eslint-disable */
  import util from '../assets/js/Util'
import {mapGetters} from "vuex";

  export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: "ModalCadastro",
    data() {
      return {util}
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
    },
    methods: {
      setTemaColor(cor) {
        return (this.tema !== undefined && this.tema !== null && this.tema !== '') ? this.tema.cor : '#000000'
      },
      getTitulo() {
        if (util.validaCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      }
    },
    mounted() {
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
      ...mapGetters('configuracao', {tema: 'pegarTema'}),
    },
  }
</script>


