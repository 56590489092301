/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const getPedidoSCH = (campo, valor, condicao) => {
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.fields = ["*"]
  filtro.filters.addFilter(campo, valor, condicao)
  return filtro
}

export const PedidoSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage

  filtro.fields = [
    'pedidos.numero',
    'dt_abertura',
    'vl_pedido',
    'status',
    'clientes.nome as cliente',
    'municipios.descricao as municipio',
    'formas_pagamento.descricao as forma_pagamento'
  ]

  filtro.join.addLeft('clientes', 'clientes.cliente_id', 'pedidos.cliente_id')
  filtro.join.addLeft('cliente_enderecos', 'cliente_enderecos.cliente_endereco_id', 'pedidos.cliente_endereco_id')
  filtro.join.addLeft('municipios', 'municipios.municipio_id', 'cliente_enderecos.municipio_id')
  // filtro.join.addLeft('pedidos_status', 'pedidos_status.pedido_id', 'pedidos.pedido_id')
  filtro.join.addLeft('formas_pagamento', 'formas_pagamento.forma_pagamento_id', 'pedidos.forma_pagamento_id')
  // filtro.addOrder('pedidos_status.dt_status', 'desc')
  filtro.addOrder('pedidos.dt_abertura', 'desc')
  filtro.addOrder('pedidos.status','asc')

  return filtro
}
