/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Tema {
  constructor (usuarioId) {
    this.usuario_tema_id = uuidV4()
    this.usuario_id = usuarioId
    this.cor = ''
    this.opacidade = ''
    this.urls_imagem = []
    this.imagens = []
  }
}
