/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getRotaSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}
export const RotaSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['nome']
    filtro.addOrder('nome', 'asc')
  return filtro
}
