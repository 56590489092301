/* eslint-disable */
import {PedidoStatus} from './pedidos_status'
import {PedidoItens} from './pedido_itens'
import { v4 as uuidV4 } from 'uuid';
export class Pedido {
  constructor (cliente_id) {


    this.pedido_id = uuidV4()
    this.numero = ''
    this.dt_abertura = ''
    this.dt_fechamento = ''
    this.cliente_id = ''
    this.cliente_endereco_id = ''
    this.vl_pedido = ''
    // this.txt_entrega = ''
    this.vl_troco = ''
    this.forma_pagamento_id = ''
    // this.observacao = ''
    this.pedidos_status =  new PedidoStatus(this.pedido_id)
    this.pedidos_itens = []
  }
}
