/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const GrupoSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['*']
  filtro.addOrder('descricao', 'asc')
  return filtro
}
