/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Produto {
  constructor () {
    this.produto_id = uuidV4()
    this.imagens = []

    this.cean = ''
    this.descricao = ''
    this.descricao_site = ''
    this.detalhes = ''
    this.marca_id = ''

    this.estoque_minimo = ''
    this.estoque = ''

    this.pr_venda = ''
    this.pr_custo = ''

    this.bloqueado = false
    this.destaque = false
    this.produtos_subgrupos = []
  }
}
