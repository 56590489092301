/* eslint-disable */
import {autentica, pegaLista, processar, setarToken} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {UsuarioSCH} from "../../../search/UsuarioSCH"
import {EventBus} from '../../../helpers/event-bus'

const url = '/usuario'

const state = {
  all: [],
  filtro: new Filtro()
}

const getters = {
  listaUsuarios: (state, getters, rootState) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['&unaccent(nome)', '&unaccent(email)'], "&'%' || unaccent('" + search + "') || '%'", 'ilike')
  },
  async getItem({commit}, id) {

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', UsuarioSCH(perPage))
  },
  async setAllUsuarios({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      list.data.forEach(function (l) {
        l.password = ''
        l.password_conf = ''
      })
      commit('SET_USUARIOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarUsuario({commit}, {usuario, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, usuario, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  async autenticarUsuario({commit}, usuario) {
    try {
      let res = await autentica(url + '/auth', usuario)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      sessionStorage.setItem('token', res.data.token)
      sessionStorage.setItem('token_expiration', res.data.token_expiration * 60 * 1000)

      this.dispatch('token/cancelarToken')
      this.dispatch('token/start', null) // Iniciando contador de expiração da sessão

      setarToken(res.data.token)

      sessionStorage.setItem('authenticated', 'true')

      if (res.data.theme.original[0] === null) {
        EventBus.$emit('USUARIO_AUTENTICADO', true, {usuario_id: res.data.theme.usuario_id}, false, res)
      } else {
        EventBus.$emit('USUARIO_AUTENTICADO', true, res.data.theme.original[0], true, res)
      }

    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('USUARIO_AUTENTICADO', false)
    }
  }
}

const mutations = {
  SET_USUARIOS(state, usuarios) {
    state.all = usuarios
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
