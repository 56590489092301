/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './modules/geral'
import municipio from './modules/Cadastro/municipios'
import diocese from './modules/Cadastro/dioceses'
import paroquia from './modules/Cadastro/paroquias'
import cliente from './modules/Cadastro/clientes'
import marca from './modules/Cadastro/marcas'
import banner from './modules/FrontEnd/banner'
import configuracao from './modules/Configuracao/configuracoes'
import departamento from './modules/Cadastro/departamentos'
import subgrupo from './modules/Cadastro/subgrupos'
import empresa from './modules/Cadastro/empresa'
import usuario from './modules/Cadastro/usuarios'
import fornecedor from './modules/Cadastro/fornecedores'
import produto from './modules/Cadastro/produtos'
import notificacao from './modules/Cadastro/notificacoes'
import pedido from './modules/Movimentacao/mov_pedido'
import requisicao from './modules/Movimentacao/requisicao'
import gerar_relatorio from './modules/Movimentacao/gerar_relatorio'
import token from './modules/token'
import rota from './modules/Cadastro/rotas'
import romaneio from './modules/Movimentacao/romaneio'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    geral,
    municipio,
    diocese,
    paroquia,
    cliente,
    marca,
    departamento,
    subgrupo,
    empresa,
    usuario,
    fornecedor,
    produto,
    notificacao,
    requisicao,
    pedido,
    banner,
    configuracao,
    gerar_relatorio,
    token,
    rota,
    romaneio
  }
})
