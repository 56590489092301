/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Departamento {
  constructor () {
    this.grupo_id = uuidV4()
    this.descricao = ''
    this.imagens = []
    this.bloqueado = false
    this.perc_cashback_cliente = 0
    this.perc_cashback_paroquia = 0
  }
}
