/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ClientePj {
  constructor (clienteId) {
    this.cliente_pj_id = uuidV4()
    this.cliente_id = clienteId
    this.razao_social = ''
    this.cnpj = ''
    this.ie = ''
  }
}
