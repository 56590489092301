/* eslint-disable */
import {lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Municipio} from "../../../models/municipio";
import {MunicipioSCH} from "../../../search/MunicipioSCH";

const url = '/municipio'

const state = {
  all: [],
  ListAll: [],
  listAllComParoquia: [],
  municipio: new Municipio(),
  filtro: new Filtro()
}

const getters = {
  listaMunicipios: (state) => {
    return state.all
  },
  listaMunicipiosComParoquia: (state) => {
    return state.listAllComParoquia
  },
  listaMunicipiosDesbloqueado: (state) => {
    return state.ListAll
  },
  pegaMunicipioId: (state) => (id) => {
    let mun = state.ListAll.find(municipio => municipio.municipio_id === id)
    return mun !== null && mun !== undefined ? mun.descricao : '** NÃO ENCONTRADO'
  },

  pegaMunicipio: (state) => (id) => {
    let mun = state.ListAll.find(municipio => municipio.municipio_id === id)
    return mun !== null ? mun : new Municipio()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(
      ['&unaccent(municipios.descricao)','&unaccent(dioceses.descricao)'],
      "&'%' || unaccent('"+search+"') || '%'",
      'ilike')
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('municipio_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('SET_MUNICIPIO', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', MunicipioSCH(perPage))
  },
  async setAllMunicipios({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_MUNICIPIOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async setAllMunicipiosComParoquia({commit}) {
    const list = (await lista(url+'/com_paroquia')).data
    commit('SET_ALL_MUNICIPIOS_COM_PAROQUIA', list)
  },

  async setAllListMunicipios({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_MUNICIPIOS', list)
  },

  async processarMunicipio({commit}, {municipio, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, municipio, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_MUNICIPIOS(state, municipios) {
    state.all = municipios
  },
  SET_ALL_MUNICIPIOS(state, municipios) {
    state.ListAll = municipios
  },
  SET_ALL_MUNICIPIOS_COM_PAROQUIA(state, municipios) {
    state.listAllComParoquia = municipios
  },
  SET_MUNICIPIO(state, municipios) {
    state.municipio = municipios
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
