/* eslint-disable */
import {lista, pegaTema, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Configuracao} from "../../../models/configuracao"

const url = '/configuracao'

const state = {
  all: [],
  tema: null
}

const getters = {
  getTermos: (state) => {
    return state.all.length > 0 ? state.all[0] : new Configuracao()
  },
  pegaConfiguracaoId: (state) => (id) => {
    let mar = state.all.find(configuracao => configuracao.configuracao_id === id)
    return mar !== null ? mar.descricao : '** NÃO ENCONTRADA'
  },
  pegarTema: state => {
    return state.tema
  }
}

const actions = {
  async setAllTema ({ commit }) {
    const list = await pegaTema('usuario/tema', {usuario_id: state.tema.usuario_id})
    commit('SET_TEMA', list.data[0].original[0])
  },
  async setAllTemaByUser ({ commit }, {id}) {
    const list = await pegaTema('usuario/tema', {usuario_id: id})
    commit('SET_TEMA', list.data[0].original[0])
    EventBus.$emit('TEMA_CONCLUIDO', true)
  },
  async setAllConfiguracao ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_CONFIGURACOES', list)
  },
  async processarConfiguracao ({ commit }, { configuracao, acao }) {
    try {
      let res = await processar('usuario/tema', configuracao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      EventBus.$emit('RETORNO_PRINCIPAL', '/tema')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Tema')
    }
  },
  async processarTermo({ commit }, { configuracao, acao }) {
    try {
      let res = await processar('configuracao', configuracao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('configuracao/setAllConfiguracao')
      EventBus.$emit('RETORNO_PRINCIPAL', '/termo')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Termo')
    }
  },
  setConfiguracao ({ commit }, configuracao) {
    commit('SET_CONFIGURACAO', configuracao)
  },
  setTema({ commit }, valor) {
    sessionStorage.setItem("usuario_id", valor.usuario_id);
    commit('SET_TEMA', valor)
  },
  setTemaImagens({ commit }, valor) {
    commit('SET_TEMA_IMAGENS', valor)
  },
  setTemaUrlImagens({ commit }, valor) {
    commit('SET_TEMA_URLS_IMAGENS', valor)
  },
}

const mutations = {
  SET_CONFIGURACOES (state, configuracao) {
    state.all = configuracao
  },
  SET_CONFIGURACAO (state, configuracao) {
    state.configuracao = configuracao
  },
  SET_TEMA (state, payload) {
    state.tema = payload
  },
  SET_TEMA_IMAGENS (state, payload) {
    state.tema.imagens = payload
  },
  SET_TEMA_URLS_IMAGENS (state, payload) {
    state.tema.urls_imagem = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
