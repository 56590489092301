/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getClienteSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}
export const ClienteSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    // filtro.fields = ['nome', 'email', 'fone1', 'bloqueado', 'cliente_pf.cpf', 'cliente_pj.cnpj', 'tp_pessoa','seq_id']
    filtro.fields = ['nome', 'email', 'fone1', 'clientes.bloqueado', 'cliente_pf.cpf', 'cliente_pj.cnpj', 'tp_pessoa','seq_id','bairro','municipios.descricao']
    filtro.addOrder('nome', 'asc')
    filtro.join.addLeft('cliente_pf','cliente_pf.cliente_id','clientes.cliente_id')
    filtro.join.addLeft('cliente_pj','cliente_pj.cliente_id','clientes.cliente_id')
    filtro.join.addLeft('cliente_enderecos','cliente_enderecos.cliente_id','clientes.cliente_id')
    filtro.join.addLeft('municipios','cliente_enderecos.municipio_id','municipios.municipio_id')
    // filtro.filters.addFilter('cliente_enderecos.principal',true,'=')
  return filtro
}
