/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Fornecedor {
  constructor () {
    this.fornecedor_id = uuidV4()
    this.nome = ''
    this.bairro = ''
    this.municipio_id = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.cep = ''
    this.bloqueado = false
  }
}
