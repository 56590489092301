/* eslint-disable */
import {filtrando, lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH"
import {ParoquiaSCH} from "../../../search/ParoquiaSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Paroquia} from '../../../models/paroquia'

const url = '/paroquia'

const state = {
  paroquia: new Paroquia(),
  all: [],
  allLista: [],
  filtro: new Filtro()
}

const getters = {
  listaParoquiaByMunicipioId: (state) => (id) => {
    let list = Object.entries(state.allLista)
    return list.filter(paroquia => paroquia[1].municipio_id === id)
  },
  listaParoquias: (state) => {
    return state.all
  },
  pegaParoquia: (state) => (id) => {
    let dio = state.allLista.find(paroquia => paroquia.paroquia_id === id)
    return dio !== null ? dio : new Paroquia()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListParoquia({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('PAROQUIAS_ALL_CONCLUIDO', true)
  },

  async searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      '&unaccent(razao)',
      '&unaccent(municipios.descricao)'
    ], "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('paroquia_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
     commit('SET_PAROQUIAS', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ParoquiaSCH(perPage))
  },
  async filtarParoquia({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_PAROQUIAS', list2)
  },

  async setAllParoquias({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_PAROQUIAS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async processarParoquia({commit}, {paroquia, acao, onSucesso, onErro}) {
    try{
      let res = await processar(url, paroquia, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    }catch(error){
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  async deletarParoquia({commit}, {paroquia, acao}){
      try{
        let res = await processar(url, paroquia, acao)
        this.dispatch('geral/setMsgSuccess', res.data.message)
        this.dispatch('paroquia/setAllParoquias')
      }catch(error){
        this.dispatch('geral/setObjMsgError', error.response.data)
      }
  }
}

const mutations = {
  SET_ALL_LIST (state, paroquias) {
    state.allLista = paroquias
  },
  SET_PAROQUIAS(state, paroquias) {
      state.all = paroquias
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
