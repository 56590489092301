/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Romaneio {
  constructor () {
    this.romaneio_id = uuidV4()
    this.rota_id = ''
    this.dt_romaneio = ''
    this.dt_encerramento = ''
    this.observacao = ''
    this.romaneio_pedidos = []
  }
}
