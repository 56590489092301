import { render, staticRenderFns } from "./SimpleTable.vue?vue&type=template&id=56533d2a&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./SimpleTable.vue?vue&type=script&async=true&lang=js&"
export * from "./SimpleTable.vue?vue&type=script&async=true&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports