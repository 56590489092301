<template>
  <span :style="'color:' + (item? 'red' : 'green')">
    {{item ? 'Sim' : 'Não'}}
  </span>
</template>

<script async>
/* eslint-disable */
  export default {
    name: "IsBlocked",
    props: ['item']
  }
</script>
