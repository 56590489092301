/* eslint-disable */
import {lista, processar, pegaLista} from '../../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../../models/filtro/filtro";
import {SubgrupoSCH} from "../../../search/SubgrupoSCH"

import {EventBus} from '../../../helpers/event-bus'
import {Subgrupo} from "../../../models/subgrupo";

const url = '/subgrupo'

const state = {
  all: [],
  allLista: [],
  subgrupo: new Subgrupo(),
  filtro: new Filtro()
}

const getters = {
  listaAllSubgrupo: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  listaSubgrupos: (state, getters, rootState) => {
    return state.all
  },
  listaSubgrupoByGrupoId: (state) => (id) => {
    let list = Object.entries(state.allLista)
    return list.filter(subgrupo => subgrupo[1].grupo_id === id)
  },
  pegaSubgrupoId: (state) => (id) => {
    let mun = state.all.find(subgrupo => subgrupo.subgrupo_id === id)
    return mun !== null ? mun.descricao : new Subgrupo()
  },
  pegaSubgrupo: (state) => (id) => {
    let mun = state.all.find(subgrupo => subgrupo.subgrupo_id === id)
    return mun !== null ? mun : new Subgrupo()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      '&unaccent(subgrupos.descricao)',
      '&unaccent(grupos.descricao)'
    ], "&'%' || unaccent('" + search + "') || '%'", 'ilike')
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SubgrupoSCH(perPage))
  },
  async setAllListSubgrupo({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('SUBGRUPOS_ALL_CONCLUIDO', true)
  },
  async setAllSubgrupos({commit}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_SUBGRUPOS', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async setAllSubgruposDesbloqueado({commit}) {
    const list = (await lista(url + '/desbloqueado')).data

    commit('SET_ALL_LIST', list)
    EventBus.$emit('SUBGRUPO_SETADO', true)
  },
  async processarSubgrupo({commit}, {subgrupo, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, subgrupo, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST(state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_SUBGRUPOS(state, subgrupos) {
    state.all = subgrupos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
