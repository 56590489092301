/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Paroquia {
  constructor () {
    this.paroquia_id = uuidV4()
    this.cnpj = ''
    this.razao = ''
    this.vl_cashback = 0
    this.municipio_id = ''
  }
}
