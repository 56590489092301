/* eslint-disable */
import {pegaDados, processar} from '../../../services/GenericAPI'
import {getRotaSCH, RotaSCH} from "../../../search/RotaSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {Rota} from '../../../models/rota';

const url = '/rota'

const state = {
  all: [],
  rota: new Rota(),
  filtro: new Filtro()
}

const getters = {
  listaRotas: (state) => {
    return state.all
  },
  pegaRota: (state) => {
    return state.rota
  },
  pegaRotaById: (state) => (id) => {
    let rota = state.all.data.find(rota => rota.rota_id === id)
    return rota !== null && rota !== undefined ? rota : []
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      '&unaccent(rotas.nome)'
    ], "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  async getItem({commit}, {id,onSucesso, onErro}) {
    try{
      var filtro = getRotaSCH('rota_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      console.log(list)
      commit('SET_ROTA', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RotaSCH(perPage))
  },
  async setAllRotas({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_ROTAS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarRota({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.rota, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setRota({commit}, rota) {
    commit('SET_ROTA', rota)
  },
  removeMunicipio({commit}, municipio) {
    commit('REMOVE_MUNICIPIO', municipio)
  },
  alteraMunicipio({commit}, municipio) {
    commit('ALTERA_MUNICIPIO', municipio)
  },
  adicionaMunicipio({commit}, municipio) {
    commit('ADICIONA_MUNICIPIO', municipio)
  }
}

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_ROTAS(state, rotas) {
    state.all = rotas
  },
  SET_ROTA(state, rota) {
    state.rota = rota
  },
  REMOVE_MUNICIPIO(state, municipio) {
    let pos = state.rota.rota_municipios.indexOf(municipio)
    state.rota.rota_municipios.splice(pos, 1)
  },
  ALTERA_MUNICIPIO(state, municipio) {
    let pos = state.rota.rota_municipios.indexOf(municipio)
    state.rota.rota_municipios.splice(pos, 1, municipio)
  },
  ADICIONA_MUNICIPIO(state, municipio) {
    state.rota.rota_municipios.splice(state.rota.rota_municipios.length, 1, municipio)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
