/* eslint-disable */
import {lista, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'

import {Empresa} from "../../../models/empresa";

const url = '/empresa'

const state = {
  all: [],
  pix_tipo: [
      {
        descricao: 'CPF/CNPJ',
        codigo: 1
      },
      {
        descricao: 'Telefone',
        codigo: 2
      },
      {
        descricao: 'Email',
        codigo: 3
      },
      {
        descricao: 'Aleatória',
        codigo: 4
      }
  ]
}

const getters = {
  listaEmpresasSemFiltro: (state) => {
    return state.all.length > 0 ? state.all[0] : new Empresa()
  },
  listTiposPix(){
      return state.pix_tipo
  },
  pegaTipoPix(){
      return state.pix_tipo[state.all[0].pix_tipo-1]
  }
}

const actions = {
  async setAllEmpresas ({ commit }) {
    const list = (await lista(url)).data
    commit('SET_EMPRESAS', list)
    EventBus.$emit('EMPRESA_SETADA', true)
  },
  async processarEmpresa({ commit }, { empresa, acao }) {
    try {
      let res = await processar(url, empresa, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('empresa/setAllEmpresas')
      // EventBus.$emit('RETORNO_PRINCIPAL', '/empresa')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Empresa')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, false, 'Empresa')
    }
  }
}

const mutations = {
  SET_EMPRESAS (state, empresas) {
    state.all = empresas
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
