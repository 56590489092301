/* eslint-disable */
const state = {
  btnAdd: false,
  btnPrint: false,
  modal: false,
  toolbarsearch: false,
  toolbarsave: false,
  sidebarId: 'principal',
  miniVariant: false,
  sidebarColor: 'rgba(0, 0, 0, 0.8)',
  modalfiltro: false,
  search: '',
  toolbarDash: null,
  dialogError: false,
  objMsgError: null,
  snackSuccess: false,
  btnDownload: false,
  tab: '',
  msgSuccess: '',
  background: '',
  checkboxImg: 1,
  chktema: true,
  showFilter: false,
  disableField:  false,
  swatches: '#000000',
  renovarLoginModal: false,
  showRenovarLoginMsg: true,
  reloadPageRenovarLogin: true
}

const getters = {
  btnAdd: state => {
    return state.btnAdd
  },
  getSearch: state => {
    return state.search
  },
  pegarShowFilter: state => {
    return state.showFilter
  },
  getDisableField: state => {
    return state.disableField
  },
}

const actions = {
  setDisableField({commit}, valor) {
    commit('SET_DESABILITAR_CAMPO', valor)
  },
  setShowFilter({commit}, valor) {
    commit('SET_SHOWFILTER', valor)
  },
  setAdd ({ commit }, valor) {
    commit('SET_ADD', valor)
  },
  setPrint ({ commit }, valor) {
    commit('SET_PRINT', valor)
  },
  setModal ({ commit }, valor) {
    commit('SET_MODAL', valor)
  },
  setToolbarSearch ({ commit }, valor) {
    commit('SET_TOOLBARSEARCH', valor)
  },
  setToolbarSave ({ commit }, valor) {
    commit('SET_TOOLBARSAVE', valor)
  },
  setSidebarID ({ commit }, valor) {
    commit('SET_SIDEBARID', valor)
  },
  setModalFiltro ({ commit }, valor) {
    commit('SET_MODALFILTRO', valor)
  },
  toggleModalFiltro ({ commit }) {
    commit('SET_MODALFILTRO', !state.modalfiltro)
  },
  setMiniVariant ({ commit }, valor) {
    commit('SET_MINIVARIANT', valor)
  },
  toggleMiniVariant ({ commit }) {
    commit('SET_MINIVARIANT', !state.miniVariant)
  },
  setSidebarColor ({ commit }, valor) {
    commit('SET_SIDEBARCOLOR', valor)
  },
  setSearch ({ commit }, valor) {
    commit('SET_SEARCH', valor)
  },
  setToolbarDash ({ commit }, valor) {
    commit('SET_TOOLBARDASH', valor)
  },
  toggleDialogError ({ commit }) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setObjMsgError ({ commit }, valor) {
    commit('SET_OBJMSGERROR', valor)
    commit('SET_DIALOGERROR', true)
  },
  toggleSnackSuccess ({ commit }) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess ({ commit }, valor) {
    commit('SET_MSGSUCCESS', valor)
    commit('SET_SNACKSUCCESS', true)
  },
  setBtnDownload ({ commit }, valor) {
    commit('SET_BTNDOWNLOAD', valor)
  },
  setTab ({ commit }, valor) {
    commit('SET_TAB', valor)
  },
  setBackground ({ commit }, valor) {
    commit('SET_BACKGROUND', valor)
  },
  setCheckBoxImg ({ commit }, valor) {
    commit('SET_CHECKBOXIMG', valor)
  },
  setChkTema ({ commit }, valor) {
    commit('SET_CHKTEMA', valor)
  },
  setSwatches ({ commit }, valor) {
    commit('SET_SWATCHES', valor)
  },
  setRenovarLogin({commit}, valor) {
    commit('SET_RENOVAR_LOGIN', valor)
  },
  setShowRenovarLoginMsg({commit}, valor) {
    commit('SET_SHOW_RENOVAR_LOGIN_MSG', valor)
  },
  setReloadPageRenovarLogin({commit}, valor) {
    commit('SET_RELOADPAGE_RENOVAR_LOGIN', valor)
  }
}

const mutations = {
  SET_DESABILITAR_CAMPO(state, payload) {
    state.disableField = payload
  },
  SET_RENOVAR_LOGIN (state, payload) {
    state.renovarLoginModal = payload
  },
  SET_SHOW_RENOVAR_LOGIN_MSG (state, payload) {
    state.showRenovarLoginMsg = payload
  },
  SET_RELOADPAGE_RENOVAR_LOGIN (state, payload) {
    state.reloadPageRenovarLogin = payload
  },
  SET_SHOWFILTER(state, payload) {
    state.showFilter = payload
  },
  SET_ADD (state, payload) {
    state.btnAdd = payload
  },
  SET_PRINT (state, payload) {
    state.btnPrint = payload
  },
  SET_MODAL (state, payload) {
    state.modal = payload
  },
  SET_TOOLBARSEARCH (state, payload) {
    state.toolbarsearch = payload
  },
  SET_TOOLBARSAVE (state, payload) {
    state.toolbarsave = payload
  },
  SET_SIDEBARID (state, payload) {
    state.sidebarId = payload
  },
  SET_MODALFILTRO (state, payload) {
    state.modalfiltro = payload
  },
  SET_MINIVARIANT (state, payload) {
    state.miniVariant = payload
  },
  SET_SIDEBARCOLOR (state, payload) {
    state.sidebarColor = payload
  },
  SET_SEARCH (state, payload) {
    state.search = payload
  },
  SET_TOOLBARDASH (state, payload) {
    state.toolbarDash = payload
  },
  SET_DIALOGERROR (state, payload) {
    state.dialogError = payload
  },
  SET_OBJMSGERROR (state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS (state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS (state, payload) {
    state.msgSuccess = payload
  },
  SET_BTNDOWNLOAD (state, payload) {
    state.btnDownload = payload
  },
  SET_TAB (state, payload) {
    state.tab = payload
  },
  SET_BACKGROUND (state, payload) {
    state.background = payload
  },
  SET_CHECKBOXIMG (state, payload) {
    state.checkboxImg = payload
  },
  SET_CHKTEMA (state, payload) {
    state.chktema = payload
  },
  SET_SWATCHES (state, payload) {
    state.swatches = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
