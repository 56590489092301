/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class BannerCGF {
  constructor (banner_id) {
    this.banner_cfg_id = uuidV4()
    this.banner_id = banner_id
    this.tamanho = 20
    this.alin_vertical = 1
    this.alin_horizontal = 1
    this.imagens = []
  }
}
