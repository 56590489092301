/* eslint-disable */
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import { CurrencyDirective } from 'vue-currency-input'

export default {
  data() {
    return {
      cor: 'green',
      info:'',
      show_list: false,
      overlay: false,
      alertError: false,
      campoError: false,
      campoError2: false,
      setPage: false,
      itemsPerPage: 15,
      itemsPages: [15, 30, 45, 60, 100, 200, 300, 400, 500, 1000],
      listaItens: [],
      erros: [],
      estilo: null,
      informacao: null,
      value: null,
      precisao_2: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      },
      dialog: {
        show: false,
        title: '',
        message: ''
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        precision: 2,
        masked: false
      },
      errorMessages: '',
      formHasErrors: false,
    }
  },
  directives: {money: VMoney, mask, currency: CurrencyDirective},
  methods: {
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    onCancel() {
      this.value = 'Não'
    },
    mobileModal(size) {
      var width = window.screen.width
      if (width <= 600) {
        size = '90%'
      } else if (width >= 601 && width <= 900) {
        size = '80%'
      } else if (width >= 901 && width <= 1300) {
        size = '70%'
      }
      return size
    },
    retorno(item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    removerMascara(str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    removeMascaraMoney(x) {
      if (this.validarCampo(x)) {
        var aux
        x = "" + x;
        if ((x.replace(",", ".") != x)) {
          if (x.replace(".", "") != x) {
            aux = x;
            x = x.replace(".", "");
          } else {
            aux = x;
          }
          if (x.replace(",", ".") != x) {
            x = x.replace(",", ".")
          } else {
            x = aux;
          }
        }
        if (isNaN(parseFloat(x))) {
          x = 0;
        } else {
          x = parseFloat(x)
        }
        return x;
      } else {
        return ''
      }
    },
    removeMascaraMonetaria(v) {
      var a
      var b
      if (v.length >= 10) {
        a = v.replace('.', '')
        a = a.replace('.', '')
        b = a.replace(',', '.')
      } else {
        a = v.replace('.', '')
        b = a.replace(',', '.')
      }
      return b
    },
    validar() {
      this.formHasErrors = false
      this.campoError2 = false
      this.campoError = false
      this.alertError = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) {
          this.formHasErrors = true
        }
        if (f === 'municipio') {
          this.campoError2 = !this.form[f]
        }

        if (f === 'bairro') {
          this.campoError = !this.form[f]
        }

        var _this = this
        var inter = setInterval(function () {
          _this.alertError = false
          clearInterval(inter)
        },3000)
        // this.$refs[f].validate(true)
      })

    },
    maskValor(valor, decimais, comPrefixo) {
      const numDecimais = (decimais !== null && decimais !== undefined) ? decimais : 2
      if (this.validarCampo(valor)) {
        if (comPrefixo !== null && comPrefixo !== undefined && comPrefixo) {
          return Number(valor).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: numDecimais
          })
        } else {
          return Number(valor).toLocaleString('pt-br', {minimumFractionDigits: numDecimais})
        }
      }
      return ''
    },
    maskCpfCnpj(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 11) {
          if (srt.length <= 3) {
            return srt.replace(/(\d{3})/g, '\$1.')
          } else if (srt.length > 3 && srt.length <= 6) {
            return srt.replace(/(\d{3})(\d{3})/g, '\$1.\$2.')
          } else if (srt.length > 6 && srt.length <= 9) {
            return srt.replace(/(\d{3})(\d{3})(\d{3})/g, '\$1.\$2.\$3\-')
          } else {
            return srt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
          }
        } else {
          if (srt.length >= 12 && srt.length <= 13) {
            return srt.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2.\$3\/')
          } else {
            return srt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
          }
        }
      }
    },
    maskCpf(cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
    },


    maskCnpj(cnpj) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, , no-useless-escape, no-useless-escape
      // return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    },


    maskCep (cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },

    maskTel (cpf) {
      if (this.validarCampo(cpf)) {
        cpf = cpf.replace(/(\d{2})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
      }
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf
    },
    getValueByKey (item,key) {
      if (!this.validarCampo(item[key])) {
        return item
      } else {
        return item[key]
      }
    },
    maskDate(data) {
      if (this.validarCampo(data)) {
        data = data.replace(/(\d{2})(\d{2})(\d{4})/g, '\$1/\$2/\$3')
      }
      return data
    },

    inverterData2(newData) {
      var xvals = newData.split('-')
      return xvals[2] + '/' + xvals[1] + '/' +xvals[0]
    },

    // Configuração  de cores
    nova_configuracao(tema) {
      // console.log('nova_configuracao: ', tema);

      var item = document.getElementById("sidebar");
      var sidebarRight = document.getElementById("sidebarRight");
      var toobarDash = document.getElementById("toobarDash");

      toobarDash.style.backgroundColor = this.setValues('cor', '#000000', tema)
      item.style.backgroundColor = this.setValues('cor', '#000000', tema)
      if (this.validarCampo(sidebarRight)){
        sidebarRight.style.backgroundColor = this.setValues('cor', '#000000', tema)
        sidebarRight.style.opacity =  this.setValues('opacidade', 0.75, tema)

      }

      toobarDash.style.opacity = this.setValues('opacidade', 0.75, tema)
      item.style.opacity = this.setValues('opacidade', 0.75, tema)

      var tinycolor = require("tinycolor2")
      var color = tinycolor(this.setValues('cor', '#000000', tema))
      var color2 = ''
      if (color.isDark()) {
        color2 = tinycolor(color).lighten(80).toString()
      } else {
        color2 = tinycolor(color).darken(80).toString()
      }
      this.mudarCorListaSidebar(color2)
    },

    percorrerElemento(classe, cor, nivel) {
      var elemento = document.getElementsByClassName(classe)
      if (nivel === 1) {
        for (var i = 0; i< elemento.length; i++) {
          elemento.item(i).style.color= cor
        }
      } else {
        for (var k = 0; k< elemento.length; k++) {
          elemento.item(k).style.color= cor
          elemento.item(k).childNodes.item(0).style.color= cor
        }
      }
    },
    mudarCorListaSidebar(cor) {
      this.percorrerElemento("v-list-item__title", cor, 1)
      this.percorrerElemento("listHoverIcon", cor, 1)
      this.percorrerElemento("v-list-item__icon v-list-group__header__prepend-icon", cor, 2)
      this.percorrerElemento("v-list-item__icon v-list-group__header__append-icon", cor, 2)
    },
    mudarBackground(cor){
      var tinycolor = require("tinycolor2")
      var color = cor !== undefined ? tinycolor(cor) :  tinycolor("#000000")
      var color2 =  tinycolor(color).lighten(45).toString()
      var inspireInicio = document.getElementById('inspireInicio')
      inspireInicio.style.background = color2
    },

    setValues(key, valor, tema) {
      var retorno = ''
      if (this.validarCampo(tema)) {
        retorno = tema[key]
      } else {
        retorno = valor
      }
      return retorno
    },
    pegarNovaLista(page) {
      this.setPage = false
      var filtro = {
        // search: this.search,
        itens_por_pagina: this.itemsPerPage,
        page: page
      }
      // this.filtarDesenhos(filtro)
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          return str.slice(0, tam) + '...'
        } else {
          return str
        }

      }

    },
    // MUDA COR DA LINHA
    mudarCorLinha(id, cor1, novaCor, ClassName) {
      var tag = document.getElementById(id)
      var classe = document.getElementsByClassName(ClassName)
      for (let i = 0; i < classe.length; i++) {
        classe[i].style.backgroundColor = cor1
      }
      tag.style.backgroundColor = novaCor
    },

    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    getDataAtual(){
      var d = this.zeroEsquerda(new Date().getDate(),2)
      var m = this.zeroEsquerda(new Date().getMonth()+1, 2)
      var a = new Date().getFullYear()
      return a + '-' + m + '-' +d
    },
    getHoraAtual(){
      var h = this.zeroEsquerda(new Date().getHours(),2)
      var m = this.zeroEsquerda(new Date().getMinutes()+1, 2)
      var s = this.zeroEsquerda(new Date().getSeconds()+1, 2)
      return h + ':' + m + ':' +s
    },

    setDataAtual(data){
      var d = this.zeroEsquerda(data.getDate(),2)
      var m = this.zeroEsquerda(data.getMonth()+1, 2)
      var a = data.getFullYear()
      return a + '-' + m + '-' +d
    },

    toDecimal(v) {
      if (this.validarCampo(v)) {
        return  parseFloat(parseFloat(v).toFixed(2))
      } else {
        return 0
      }
    },

    camelize(str) {
      let ignorar = ['e','a','o','da','de','do','em','um','uma','uns','umas','os','das','as']
      let result = ''
      let splited = str.split(' ');

      // Seta a primeira letra de cada palavra pra maiúsculo e o restante pra minúsculo, ignorando palavras do array 'ignorar'
      splited.forEach(function(valor, id) {
          if( id === 0 || !(ignorar.includes( valor.toLowerCase() )) ) {
            result += valor.replace(/(?:^\w|[A-Z-À-Ú-à-ú]|\b\w)/g, function (word, index) {
              return index === 0 ? word.toUpperCase() : word.toLowerCase()
            })
          } else result += valor.toLowerCase()

          result += id >= splited.length-1 ? '' : ' '
      })

      return result
    },

    isNotNull(data){
        return (data)
    },

    get_pdf(link) {
        window.open(link, '_blank')
    },

    formatarData(sData){
      if(this.validarCampo(sData)) {
        // Esse replace é pra resolver problema da Data estar voltando 1 dia...
        var data = new Date(sData.replace(/-/g, '\/'))

        var dia  = data.getDate().toString()
        dia = (dia.length == 1) ? '0'+dia : dia

        var mes  = (data.getMonth()+1).toString() //+1 pois no getMonth Janeiro começa com zero.
        mes = (mes.length == 1) ? '0'+mes : mes

        var ano = data.getFullYear()
        return dia+"/"+mes+"/"+ano
      }
      
      return data
    }

  }
}
