/* eslint-disable */
import {filtrando, lista, pegaDados, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH"
import {BannerSCH} from "../../../search/BannerSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Banner} from '../../../models/banner'

const url = '/banner'

const state = {
  all: [],
  banner: new Banner(),
  allLista: [],
  filtro: new Filtro()
}

const getters = {
  listaBanners: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaBanner: (state) => {
    return state.banner
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  setBanner({commit}, banner) {
    commit('SET_BANNER', banner)
  },

  async setAllListBanner({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('BANNERS_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['descricao'] ,
      "%"+search+"%",
      'like'
    )
  },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('banner_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('SET_BANNERS', list.data[0])
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BannerSCH(perPage))
  },
  // Pag
  async filtarBanner({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_BANNERS', list2)
  },

  async setAllBanners({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_BANNERS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async setAllBannersDesbloqueada({commit}) {
    const list = (await lista(url + '/desbloqueado')).data
    commit('SET_BANNERS', list)
  },
  async processarBanner({commit}, { acao, onSucesso, onErro}) {
      try {
        let res = await processar(url, state.banner, acao)
        this.dispatch('geral/setMsgSuccess', res.data.message)
        // this.dispatch('banner/setAllBanners')
        onSucesso()
      } catch (error) {
        console.log(error);
        this.dispatch('geral/setObjMsgError', error.response.data)
        onErro()
        // EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'BannerCadastrar')

      }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_BANNERS(state, banners) {
    state.all = banners
  },
  SET_BANNER(state, banner) {
    state.banner = banner
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
