/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
import {BannerCGF} from "./banner_cfg";
export class Banner {
  constructor () {
    this.banner_id = uuidV4()
    this.tp_banner = ''
    // this.ordem = ''
    this.router_name = ''
    this.router_id = ''
    this.bloqueado = ''
    this.imagens = []
    this.bloqueado = false
    this.banner_cfg = new BannerCGF(this.banner_id)
  }
}
