/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import './assets/css/main.scss'
import 'font-awesome/css/font-awesome.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VuejsDialog from 'vuejs-dialog'
import ContainerForm from '../src/components/ContainerForm.vue'
import ModalCadastro from '../src/components/ModalCadastro.vue'
import store from './store/index'
import { sync } from 'vuex-router-sync'
import ProCard from './components/ProCard.vue'
import TokenExpirationTimer from './components/TokenExpirationTimer.vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import {Money} from 'v-money'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'cropperjs/dist/cropper.css';

import PText from './components/pComponents/pText'
import PNumeric from './components/pComponents/pNumeric'
import PLabel from './components/pComponents/pLabel'

import VCurrencyField  from 'v-currency-field'

import { ValidationProvider, extend, ValidationObserver   } from 'vee-validate';
import { required, min, confirmed, between } from 'vee-validate/dist/rules';
import TableIterator from "./components/TableIterator";
import IsBoolean from "./components/IsBoolean";
import SimpleTable from "./components/SimpleTable";

Vue.use(VCurrencyField)
Vue.component(VueCountdown.name, VueCountdown)
Vue.component('pro-container-form', ContainerForm)
Vue.component('pro-card', ProCard)
Vue.component('pro-modal-cadastro', ModalCadastro)
Vue.component('pro-money', Money)
Vue.component('is-boolean', IsBoolean)
Vue.component('v-validate', ValidationProvider)
Vue.component('v-validate-observer', ValidationObserver  )
Vue.component('table-iterator', TableIterator)
Vue.component('simple-table', SimpleTable)
Vue.component('p-text', PText)
Vue.component('p-numeric', PNumeric)
Vue.component('p-label', PLabel)
Vue.component('token-expiration-timer', TokenExpirationTimer)

extend('required', {  ...required,  message: 'Este campo é obrigatório'});
extend('confirmed', {  ...confirmed,  message: 'O campo {_field_} e o campo senha devem ser iguais'});

extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: 'o campo {_field_} deve conter pelo menos {length} caracteres.'
});

extend('between', {  ...between,
  params: ['min', 'max'],
  message: 'o campo deve ser entre {min} e {max}.'});


Vue.use(Vuetify, { theme: {
  primary: '#1d94ff',
  secondary: '#0a593b',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
} })

Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Continuar',
  cancelText: 'Cancelar',
  animation: 'fadeIn',
  type: 'alert'
})

Vue.config.productionTip = false
sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}


new Vue({
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
