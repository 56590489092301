/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const MunicipioSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['municipios.*', 'dioceses.descricao as diocese']
    filtro.join.addLeft('dioceses', 'dioceses.diocese_id', 'municipios.diocese_id')
    filtro.addOrder('municipios.descricao', 'asc')
    return filtro
}
