/* eslint-disable */
import {lista, pega, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro} from "../../../models/filtro/filtro";
import {getProdutoSCH, ProdutoAllSCH, ProdutoSCH} from "../../../search/ProdutoSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Produto} from '../../../models/produto'

const url = '/produto'

const state = {
  all: [],
  allList: [],
  allListDados: [],
  produto: new Produto(),
  filtro: new Filtro(),
  estoqueBloqueado: false
}

const getters = {
  listaProdutos: (state) => {
    return state.all
  },
  listaAllProdutos: (state) => {
    return state.allList
  },
  listaAllProdutosDados: (state) => {
    return state.allListDados
  },
  pegaProdutoId: (state) => (id) => {
    let pro = state.all.find(produto => produto.produto_id === id)
    return pro !== null && pro !== undefined ? pro.descricao : '** NÃO ENCONTRADA'
  },
  pegaProduto: (state) => {
    return state.produto
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  getEstoqueBloqueado: (state) => {
      return state.estoqueBloqueado
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
       'produtos.cean',
       '&unaccent(produtos.descricao_site)',
       '&unaccent(produtos.descricao)',
       '&unaccent(marcas.descricao)',
        'produtos.bloqueado',
        'produtos.destaque'
      ],
      "&'%' || unaccent('"+search+"') || '%'",
        'ilike'
      )
  },
  async getItem({commit}, id) {
     var filtro = getProdutoSCH('produtos.produto_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
     commit('SET_PRODUTO', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)

  },
  async setEstoqueBloqueado({commit}, id){
     const res = (await pega(url+'/estoque_bloqueado', id))
    state.estoqueBloqueado = res.data[0] === undefined ? false : (res.data[0].count > 0)
  },
  async setAllListProdutos({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_PRODUTO', list)
  },

  async setAll_Produtos({commit}) {
    const list = (await lista(url+'/all_produtos')).data
    commit('SET_ALL_PRODUTO', list)
  },

  async setListAllProdutos({commit}) {
    const list = (await lista(url+'/all_list_prod')).data
    commit('SET_ALL_PRODUTO', list)
  },

  async setAllListProdutosDados({commit}) {
    const list = (await lista(url+'/all2', state.filtro)).data
    // console.log('SET_ALL_PRODUTO_DADOS:', list)
    commit('SET_ALL_PRODUTO_DADOS', list)
  },

  setFiltro2({commit}, perPage) {
     commit('SET_FILTRO', ProdutoAllSCH(perPage))
  },
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ProdutoSCH(perPage))
  },
  async setAllProdutos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_PRODUTOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarProduto({commit}, {acao, onSucesso, onErro}) {
    try {
      state.produto.urls_imagem = null // só pra diminuir o tamanho do que manda pro server
      let res = await processar(url, state.produto, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setProduto({commit}, produto) {
    commit('SET_PRODUTO', produto)
  },
  setaImagens({commit}, imagens) {
    commit('SETA_IMAGENS', imagens)
  },

  removeFornecedor({commit}, fornecedor) {
    commit('REMOVE_FORNECEDOR', fornecedor)
  },
  alteraFornecedor({commit}, fornecedor) {
    commit('ALTERA_FORNECEDOR', fornecedor)
  },
  adicionaFornecedor({commit}, fornecedor) {
    commit('ADICIONA_FORNECEDOR', fornecedor)
  }
}

const mutations = {
  SET_ALL_PRODUTO(state, produtos) {
    state.allList = produtos
  },
  SET_ALL_PRODUTO_DADOS(state, produtos) {
    state.allListDados = produtos
  },
  SET_PRODUTOS(state, produtos) {
    state.all = produtos
  },
  SET_PRODUTO(state, produto) {
    state.produto = produto
  },

  // IMAGENS
  SETA_IMAGENS(state, imagens) {
    state.produto.imagens = imagens
  },

  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
