/* eslint-disable */
import {lista, pegaLista, processar} from '../../../services/GenericAPI'
import {ClienteSCH, getClienteSCH} from "../../../search/ClienteSCH"
import {Filtro, Where} from "../../../models/filtro/filtro";
import {Cliente} from '../../../models/cliente'
import {ClientePf} from '../../../models/cliente_pf'
import {ClientePj} from '../../../models/cliente_pj'

const url = '/cliente'

const state = {
  all: [],
  allList: [],
  cliente: new Cliente(),
  filtro: new Filtro()
}

const getters = {
  listaAllClientes: (state) => {
    return state.allList
  },
  listaClientes: (state) => {
    return state.all
  },
  pegaCliente: (state) => {
    return state.cliente
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, {search, campo}) {
    var ar = []
    if (campo.length > 0) {
      for(var i= 0; i<campo.length;i++){
          if (campo[i].campo === 'tp_pessoa') {
          ar.push('cliente_pf.cpf')
          ar.push('cliente_pj.cnpj')
        } else {
          ar.push(campo[i].campo)
        }
      }
    } else {
      ar = [
        '&unaccent(clientes.email)',
        '&unaccent(clientes.nome)',
        'fone1',
        'cliente_pf.cpf',
        'cliente_pj.cnpj',
        'municipios.descricao'
      ]
    }
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(ar, "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  async getItem({commit}, {id,onSucesso, onErro}) {

    try{
      var filtro = getClienteSCH('cliente_id', id, '=')
      const list = (await pegaLista(url, filtro)).data
      if (list.data[0].cliente_enderecos.length > 1) {
        var endereco = list.data[0].cliente_enderecos.sort(function (a, b) {
          return a.logrado < b.logrado ? -1 : a.logrado > b.logrado ? 1 : 0
        })
        list.data[0].cliente_enderecos = endereco
      }


      commit('SET_CLIENTE', list.data[0])
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }


  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ClienteSCH(perPage))
  },
  async setAllListClientes({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
  },
  async setAllListClientesCashback({commit}) {
    const list = (await lista(url + '/all_cashback')).data
    commit('SET_ALL_LIST', list)
  },
  async processarSenha({commit}, {cliente,onSucesso,onErro}) {
    try {
      const res = (await processar(url + '/change-password', cliente, 'gravar'))
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async setAllClientes({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      // console.log('SET_CLIENTES:', list.data)
      commit('SET_CLIENTES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarCliente({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.cliente, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setCliente({commit}, cliente) {
    commit('SET_CLIENTE', cliente)
  },
  removeEndereco({commit}, endereco) {
    commit('REMOVE_ENDERECO', endereco)
  },
  alteraEndereco({commit}, endereco) {
    commit('ALTERA_ENDERECO', endereco)
  },
  adicionaEndereco({commit}, endereco) {
    commit('ADICIONA_ENDERECO', endereco)
  }
}

const mutations = {
  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CLIENTES(state, clientes) {
    state.all = clientes
  },
  SET_CLIENTE(state, cliente) {
    if (cliente.tp_pessoa === 'J') {
      cliente.cliente_pf = new ClientePf()
    } else if (cliente.tp_pessoa === 'F') {
      cliente.cliente_pj = new ClientePj()
    }
    state.cliente = cliente
  },
  REMOVE_ENDERECO(state, endereco) {
    let pos = state.cliente.cliente_enderecos.indexOf(endereco)
    state.cliente.cliente_enderecos.splice(pos, 1)
  },
  ALTERA_ENDERECO(state, endereco) {
    let pos = state.cliente.cliente_enderecos.indexOf(endereco)
    state.cliente.cliente_enderecos.splice(pos, 1, endereco)
  },
  ADICIONA_ENDERECO(state, endereco) {
    state.cliente.cliente_enderecos.splice(state.cliente.cliente_enderecos.length, 1, endereco)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
