/* eslint-disable */
import { API, setToken } from '../api'
import { acaoApi } from '../helpers/enums'
import {EventBus} from '../helpers/event-bus'

export const lista = (url) => {
  return API.get(url)
}

export const filtrando = (url, objeto) => {
  return API.get(url, {params:objeto})
}
export const pegaLista= (url, data) => {
  return API.get(url, { params: {data: data} })
}
export const pegaArquivo= async (url, data) => {
  let retorno = await API.get(url, { params: {data: data}})
  return process.env.VUE_APP_API_URL + 'viewPdfReport/' + retorno.data
}

export const pegaTema = (url, usuario) => {
  return API.get(url, { params: usuario })
}

export const pegaDados = (url, data) => {
  // setToken(sessionStorage.getItem('token'))
  return API.post(url, data)
}

/* export const processar = (url, objeto, acao) => {
  setToken(sessionStorage.getItem('token'))
  if (acao === acaoApi.GRAVAR) {
    var res = API.post(url, objeto)
  } else if (acao === acaoApi.REMOVER) {
    var res = API.delete(url, { data: objeto })
  }
  
  res.catch(
    (erro) => {
      if(erro.response.status == 401){
        var renovar = (erro.response.data.message == "Token has expired")
        EventBus.$emit('RENOVAR_LOGIN', renovar)
        console.log('token')
      }
    }
  )

  return res
} */
export const processar = (url, objeto, acao) => {
  setToken(sessionStorage.getItem('token'))
  if (acao === acaoApi.REMOVER) {
    var res = API.delete(url, {data: objeto})
  } else {
    var res = API.post(url, objeto)
  }

  res.catch(
    (erro) => {
      if(erro.response.status == 401){
        var renovar = (erro.response.data.message == "Token has expired")
        erro.response.data.message = "Sua sessão expirou! Faça o Login novamente."
        EventBus.$emit('RENOVAR_LOGIN', renovar)
      }
    }
  )
  return res
}

export const pega = (url, id) => {
  return API.get(url + '/' + id)
}
export const autentica = (url, objeto) => {
  return API.post(url, objeto)
}

export const setarToken = (token) => {
  setToken(token)
}

export const geraUrlRelatorio = async (url, data) => {
    let retorno = await API.post(url, data)
    return process.env.VUE_APP_API_URL + 'viewPdfReport/' + retorno.data
}
