/* eslint-disable */
import {lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {GrupoSCH} from "../../../search/GrupoSCH"

import {EventBus} from '../../../helpers/event-bus'
import {Departamento} from "../../../models/departamento";

const url = '/grupo'

const state = {
  all: [],
  allLista: [],
  anexos: [],
  listaAllDepartamentos: [],
  filtro: new Filtro()
}

const getters = {
  listaAllDeptos: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  listaDepartamentos: (state, getters, rootState) => {
    return state.all
  },
  listaDepartamentosDesbloqueado: (state) => {
    return state.listaAllDepartamentos
  },
  pegaDepartamentoId: (state) => (id) => {
    let mun = state.all.find(departamento => departamento.grupo_id === id)
    return mun !== null ? mun.descricao : new Departamento()
  },
  pegaDepartamento: (state) => (id) => {
    let mun = state.listaAllDepartamentos.find(departamento => departamento.grupo_id === id)
    return mun !== null ? mun : new Departamento()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListComSubgrupo({ commit }) {
    const list = (await lista(url + '/lista_com_subgrupo')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('GRUPOS_ALL_CONCLUIDO', true)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      '&unaccent(grupos.descricao)'
    ], "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', GrupoSCH(perPage))
  },
  async setAllDepartamentos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_DEPARTAMENTOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setListDepartamentos({commit}) {
    const list = (await lista(url+'/all')).data
    commit('SET_ALL_DEPTOS', list)
  },

  async setAllDepartamentosDesbloqueado({commit}) {
    const list = (await lista(url+'/desbloqueado_site')).data
    commit('SET_ALL_DEPTOS', list)
  },

  async processarDepartamento({commit}, {departamento, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, departamento, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },

}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_DEPARTAMENTOS(state, departamentos) {
    state.all = departamentos
  },
  SET_ALL_DEPTOS(state, departamentos) {
    state.listaAllDepartamentos = departamentos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
