/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Municipio {
  constructor () {
    this.municipio_id = uuidV4()
    this.descricao = ''
    this.mensagem = ''
    this.diocese_id = ''
    this.bloqueado = false
    this.ordem_logistica = 0
  }
}
