/* eslint-disable */
import {lista, pegaArquivo, pegaDados, pegaLista, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Pedido} from "../../../models/movimentacao/pedido";
import {Filtro} from "../../../models/filtro/filtro";
import {getPedidoSCH, PedidoSCH} from "../../../search/PedidoSCH";

const url = '/pedido'

const state = {
  all: [],
  allItensSelecao: [],
  allPedidosSeparados: [],
  pedido: new Pedido(),
  forma_pagamento: [],
  pdf: '',
  filtro: new Filtro()
}

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  listaPedidos: (state) => {
    return state.all
  },
  listaPedidosSeparados: (state) => {
    return state.allPedidosSeparados
  },
  pegaPedido: (state) => {
    return state.pedido
  },
  pegaPedidoSeparado: (state) => (id) => {
    let ped = state.allPedidosSeparados.find(pedido => pedido.pedido_id === id)
    return ped !== null && ped !== undefined ? ped : []
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}

const actions = {
  searchItem({commit}, {search, campo}) {
    var ar = []
    if (campo.length > 0) {
      for(var i= 0; i<campo.length;i++){
        ar.push(campo[i].campo)
      }
    } else {
      ar = [
        '&unaccent(clientes.nome)',
        'pedidos.numero',
        'vl_pedido',
        'formas_pagamento.descricao',
        'municipios.descricao'
      ]
    }
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(ar, "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },



  async getItem({commit}, {pedido_id, onSucesso,onErro}) {
    try{
    var filtro = getPedidoSCH('pedidos.pedido_id', pedido_id, '=')
    const list = (await pegaDados(url + '/get', filtro)).data
    commit('SET_PEDIDO', list.data[0])
    onSucesso()
  } catch (error) {
      onErro()
    }
  },
  async setAllFormasPagamento({commit}) {
    const list = (await lista(url+ '/forma_pagamento')).data
    commit('SET_FORMAS_PAGAMENTO', list)
  },

  async setAllItensSelecao({commit}) {
    try {
      const list = (await pegaLista(url+'/itens_selecao', state.filtro)).data
      commit('SET_ITENS_SELECAO', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }

  },

  async gerarEmissaoPedido({commit}, {item, onSucesso, onErro}) {
    try {
      const resposta = (await pegaArquivo(url+'/pdf', item))
      commit('SET_PDF', resposta)
      onSucesso(resposta)
    } catch (error) {
      onErro()
    }

  },

  async setAllPedidos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_PEDIDOS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setAllPedidosSeparados({commit}, {onSucesso, onErro}) {
    try {
      // state.filtro.filters.addFilter('status', '5', '=')
      const list = (await pegaLista(url+'/pedidos-separados')).data
      commit('SET_PEDIDOS_SEPARADOS', list)
      if(onSucesso != null) onSucesso()
    } catch (error) {
      console.log(error);
      if(onErro != null) onErro()
    }
  },

  async processarPedido({commit}, {pedido, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, pedido, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', PedidoSCH(perPage))
  },
}

const mutations = {
  SET_ITENS_SELECAO(state, pedidos) {
    state.allItensSelecao = pedidos
  },
  SET_FORMAS_PAGAMENTO(state, pedidos) {
    state.forma_pagamento = pedidos
  },
  SET_PEDIDOS(state, pedidos) {
    state.all = pedidos
  },
  SET_PEDIDOS_SEPARADOS(state, pedidos) {
    state.allPedidosSeparados = pedidos
  },
  SET_PEDIDO(state, pedido) {
    state.pedido = pedido
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PDF(state, pdf) {
    state.pdf = pdf
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
