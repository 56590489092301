/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Empresa {
  constructor () {
    this.empresa_id = uuidV4()
    this.razao_social = ''
    this.nome_fantasia = ''
    this.cnpj = ''
    this.ie = ''
    this.bairro = ''
    this.municipio_id = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.cep = ''
    this.fone_1 = ''
    this.fone_2 = ''
    this.email = ''
    this.pix_tipo = ''
    this.pix_chave = ''
    this.pix_recebedor = ''
    this.pix_cidade = ''
    this.perc_cashback_cliente = 0
    this.perc_cashback_paroquia = 0
  }
}
