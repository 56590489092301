/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getProdutoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}

export const ProdutoSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = [
      'produtos.cean',
      'produtos.descricao',
      'produtos.descricao_site',
      'produtos.bloqueado',
      'produtos.estoque_minimo',
      'produtos.estoque',
      'produtos.pr_custo',
      'produtos.pr_venda',
      'produtos.destaque',
      'marcas.descricao as marca_desc'
    ]

  filtro.addOrder('produtos.descricao', 'asc')
  // filtro.addOrder('marcas.descricao', 'asc')
  filtro.join.addLeft('marcas','marcas.marca_id','produtos.marca_id')
  return filtro
}


export const ProdutoAllSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = [
    'produtos.cean',
    'produtos.descricao',
    'produtos.descricao_site',
    'produtos.pr_venda'
  ]

  filtro.filters.addFilter('produtos.bloqueado', 'false', '=')
  filtro.filters.addFilter('produtos.estoque', 0, '>')
  filtro.filters.addFilter('produtos.pr_venda', 0, '>')
  filtro.filters.addFilterRaw('(estoque >= estoque_minimo)')
  filtro.addOrder('produtos.descricao_site', 'asc')

  return filtro
}

export const Produto_AllSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = [
    'produtos.cean',
    'produtos.descricao',
    'produtos.descricao_site',
    'produtos.pr_venda'
  ]
  filtro.filters.addFilter('produtos.bloqueado', 'false', '=')
  filtro.addOrder('produtos.descricao_site', 'asc')

  return filtro
}
