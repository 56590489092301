/* eslint-disable */
import {processar, geraUrlRelatorio, pegaDados} from '../../../services/GenericAPI'
import {RomaneioSCH, getRomaneioSCH} from "../../../search/RomaneioSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {Romaneio} from '../../../models/romaneio';

const url = '/romaneio'

const state = {
  all: [],
  romaneio: new Romaneio(),
  filtro: new Filtro()
}

const getters = {
  listaRomaneios: (state) => {
    return state.all
  },
  pegaRomaneio: (state) => {
    return state.romaneio
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  temPedidoNaLista: (state) => (pedido_id) => {
    let ped = state.romaneio.romaneio_pedidos.find(pedido => pedido.pedido_id === pedido_id)
    return ped !== null && ped !== undefined
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      'romaneios.dt_romaneio'
    ], "&'%' || '"+search+"' || '%'", 'ilike')
  },
  async getItem({commit}, {id,onSucesso, onErro}) {

    try{
      var filtro = getRomaneioSCH('romaneio_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_ROMANEIO', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RomaneioSCH(perPage))
  },
  async setAllRomaneios({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_ROMANEIOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarRomaneio({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.romaneio, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)

    }
  },
  async processarEncerrar({commit}, {romaneio, onSucesso, onErro}) {
    try {
      let res = await processar(url+'/encerrar', romaneio)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  async emissaoRomaneio({commit}, {onSucesso, onErro}) {
    try {
      let res = await geraUrlRelatorio(url+'/emitir', state.filtro)
      onSucesso(res)
    } catch (error) {
      onErro()
    }
  },
  setRomaneio({commit}, romaneio) {
    commit('SET_ROMANEIO', romaneio)
  },
  removePedido({commit}, pedido) {
    commit('REMOVE_PEDIDO', pedido)
  },
  alteraPedido({commit}, pedido) {
    commit('ALTERA_PEDIDO', pedido)
  },
  adicionaPedido({commit}, pedido) {
    commit('ADICIONA_PEDIDO', pedido)
  }
}

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_ROMANEIOS(state, romaneios) {
    state.all = romaneios
  },
  SET_ROMANEIO(state, romaneio) {
    state.romaneio = romaneio
  },
  REMOVE_PEDIDO(state, pedido) {
    let pos = state.romaneio.romaneio_pedidos.indexOf(pedido)
    state.romaneio.romaneio_pedidos.splice(pos, 1)
  },
  ALTERA_PEDIDO(state, pedido) {
    let pos = state.romaneio.romaneio_pedidos.indexOf(pedido)
    state.romaneio.romaneio_pedidos.splice(pos, 1, pedido)
  },
  ADICIONA_PEDIDO(state, pedido) {
    state.romaneio.romaneio_pedidos.splice(state.romaneio.romaneio_pedidos.length, 1, pedido)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
