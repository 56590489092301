/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getRequisicaoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}
export const RequisicaoSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage
  // filtro.addOrder('Requisicaos.descricao', 'asc')
  filtro.fields = [
    'requisicoes.tp_mov',
    'requisicoes.qtd_mov',
    'requisicoes.pr_custo',
    'produtos.descricao_site',
    'fornecedores.nome',
    'requisicoes.produto_id',
    'requisicoes.fornecedor_id',
  ]

  filtro.join.addLeft('produtos', 'produtos.produto_id', 'requisicoes.produto_id')
  filtro.join.addLeft('fornecedores', 'fornecedores.fornecedor_id', 'requisicoes.fornecedor_id')
  return filtro
}
