<template>
  <div class="app-login">
    <v-card flat>
      <v-row pb-2 align="center" justify="center">
        <v-col cols="12" sm="8" offset-sm2 align-self="center">
          <v-card class="card--flex-toolbar" style="background: #EEEEEE !important;">
            <v-card-text>
              <v-layout row wrap>
                <v-col cols="12" sm="12" md="6">
                  <div style="text-align: center">
                    <div class="logo">
                      <img src="../assets/login/login_admin.svg" alt="" width="745" height="313"/>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <div class="formLogin">
                    <h1 class="login">Login</h1>
                    <v-form v-model="valid">
                      <v-container>
                        <v-layout row wrap>
                          <v-col cols="12">
                            <v-text-field dense outlined hide-details v-model="user.email" label="Usuário"
                                          id="usuario"
                                          prepend-inner-icon="mdi-account"
                                          v-on:keyup="PressEnter(user.password, user.email)"
                                          required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field dense outlined hide-details v-model="user.password" label="Senha"
                                          prepend-inner-icon="mdi-lock"
                                          :type="mostrar ? 'text' : 'password'"
                                          id="mdi-lock"
                                          v-on:keyup="PressEnter(user.password, user.email)"
                                          @click:append="mostrar = !mostrar"
                                          :append-icon="mostrar ? 'mdi-eye' : 'mdi-eye-off'"
                                          required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <div style="text-align: right;">
                              <v-btn @click="efetuaLogin(user)" color="primary" :loading="spinner2">Entrar</v-btn>
                            </div>
                          </v-col>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </div>
                </v-col>
              </v-layout>
              <!--<v-layout row wrap>-->
                <!--<v-col cols="12">-->
                  <!--<div class="logoprod">-->
                    <!--<img src="../assets/img/logo.png" alt="" width="400" height="auto">-->
                  <!--</div>-->
                <!--</v-col>-->
              <!--</v-layout>-->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>


    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-footer :fixed="fixed" app style="padding: 0 !important;">
      <div class="footerLogin">
        <span style="font-size: 0.9rem">Copyrigth &copy; {{ new Date().getFullYear() }} <a href="http://www.prodatanet.com.br/">Prodata Informática</a>. Todos os direitos reservados.</span>
      </div>
    </v-footer>
  </div>
</template>

<script async>
  /* eslint-disable */
  import {EventBus} from '../helpers/event-bus'
  import {mapActions, mapState} from "vuex";
  import router from '../router'
  import {Tema} from "../models/tema";

  export default {
  data: () => ({
    fixed: true,
    user: [],
    mostrar: false,
    spinner2: false,
    valid: false,
    name: '',
    nameRules: [
      v => !!v || 'Digite o usuario',
      v => v.length <= 1 || 'O nome deve ter mais de 1 letra'
    ],
    senha: '',
    senhaRules: [
      v => !!v || 'Digite a senha',
      v => v.length <= 1 || 'A senha deve ter mais de 1 letra'
    ]
  }),

  methods: {
    ...mapActions('usuario', ['autenticarUsuario']),
    ...mapActions('geral', ['setModal', 'toggleDialogError', 'toggleSnackSuccess']),
    ...mapActions('configuracao', ['setTema']),
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    retorno(item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    efetuaLogin(usuario) {
      this.spinner2 = true
      var array = {
        email: this.retorno(this.validarCampo(usuario.email), true, usuario.email, ""),
        password: this.retorno(this.validarCampo(usuario.password), true, usuario.password, "")
      }
      this.autenticarUsuario(array)
    },
    PressEnter(senha, usuario) {
      var _this = this
      var user = document.getElementById('usuario')
      var senhaId = document.getElementById('password')

      user.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          if (!_this.validarCampo(usuario)) {
            user.focus()
          } else if (!_this.validarCampo(senha)) {
            senhaId.focus()
          } else {
            _this.efetuaLogin(_this.user)
          }
        }
      }, false)
      senhaId.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          if (!_this.validarCampo(senha)) {
            senhaId.focus()
          } else {
            if (!_this.validarCampo(usuario)) {
              user.focus()
            } else {
              _this.efetuaLogin(_this.user)
            }
          }
        }
      }, false)
    },
  },
  computed: {
    ...mapState('geral', {
      dialogError: 'dialogError',
      objMsgError: 'objMsgError',
      snackSuccess: 'snackSuccess',
      msgSuccess: 'msgSuccess'
    }),
  },
  mounted() {
    var _this = this
    EventBus.$on('USUARIO_AUTENTICADO', (sucesso, usuario, existe) => {
      this.spinner2 = false
      if (sucesso) {
        var inter = setInterval(function () {
          if (!existe) {
            var tema = new Tema(usuario.usuario_id)
            tema.cor = '#000000'
            tema.opacidade = 0.8
            _this.setTema(tema)
          } else {
            _this.setTema(usuario)
          }
          router.push({name: 'Principal'}).catch(err => {
          })
          clearInterval(inter)
        }, 1000)
      }
    })

  }
}
</script>
<style scoped>
.topback {
  background: rgba(0, 0, 0, 0.7) !important;
  height: 200px !important;
}

.card--flex-toolbar {
  margin-top: 50px;
}

.formLogin {
  width: 90%;
  margin: 0 auto;
  padding: 2%;
}

.logo {
  text-align: center;
  padding-top: 5%;
}

.logo img {
  width: 70%;
}

.logoprod {
  text-align: right;
  padding-right: 3%;
}

.logoprod img {
  width: 10%;
}

.br {
  display: block;
}

@media screen and (min-width: 200px) and (max-width: 450px) {
  .card--flex-toolbar {
    margin-top: -200px !important;
  }

  .login {
    font-size: 1.5rem;
  }

  .br {
    display: none;
  }

  .logoprod {
    padding-right: 15%;
  }

  .logoprod img {
    width: 40%;
  }
}

@media screen and (min-width: 451px) and (max-width: 599px) {
  .card--flex-toolbar {
    margin-top: 180px !important;
  }

  .br {
    display: none;
  }

  .logoprod {
    padding-right: 10%;
  }

  .logoprod img {
    width: 30%;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .card--flex-toolbar {
    margin-top: 180px !important;
  }

  .logoprod {
    padding-right: 10%;
  }

  .logoprod img {
    width: 30%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1600px) {
  .topback {
    height: 300px
  }

  .card--flex-toolbar {
    margin-top: 180px !important;
  }

  .logo {
    text-align: center;
    padding-top: 15%;
  }

  .logo img {
    width: 90%;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .topback {
    height: 300px
  }

  .card--flex-toolbar {
    margin-top: 180px !important;
  }

  .logo {
    text-align: center;
    padding-top: 15%;
  }

  .logo img {
    width: 90%;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1919px) {
  .topback {
    height: 420px
  }

  .card--flex-toolbar {
    margin-top: 180px !important;
  }

  .logo {
    text-align: center;
    padding-top: 15%;
  }

  .logo img {
    width: 90%;
  }
}

@media screen and (min-width: 1920px) {
  .topback {
    height: 350px
  }

  .card--flex-toolbar {
    margin-top: 180px !important;
  }

  .logo {
    text-align: center;
    padding-top: 15%;
  }

  .logo img {
    width: 90%;
  }

  .logoprod {
    padding-right: 10%;
  }

  .logoprod img {
    width: 15%;
  }
}
</style>
