/* eslint-disable */
import {filtrando, lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH"
import {NotificacaoSCH} from "../../../search/NotificacaoSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Notificacao} from '../../../models/notificacao'

const url = '/notificacao'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro()
}

const getters = {
  listaNotificacoes: (state) => {
    return state.all
  },
  pegaNotificacaoId: (state) => (id) => {
    let mar = state.all.find(notificacao => notificacao.notificacao_id === id)
    return mar !== null ? mar.descricao : '** NÃO ENCONTRADA'
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListNotificacao({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('NOTIFICACOES_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(
      ['&unaccent(mensagem)', '&unaccent(titulo)'],
      "&'%' || unaccent('"+search+"') || '%'", 'ilike'
    )
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('notificacao_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('SET_NOTIFICACOES', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', NotificacaoSCH(perPage))
  },
  async filtarNotificacao({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_NOTIFICACOES', list2)
  },

  async setAllNotificacoes({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_NOTIFICACOES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async setAllNotificacoesDesbloqueada({commit}) {
    const list = (await lista(url + '/alldesbloqueada')).data

    commit('SET_ALL_LIST', list)
  },
  async processarNotificacao({commit}, {notificacao, acao, onSucesso, onErro}) {
    try {

      let res = await processar(url, notificacao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  async enviarNotificacao({commit}, {notificacao, acao, onFinish}) {
    try {
      let res = await processar(url + '/enviarNotificacao', notificacao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
    onFinish()
  }
}

const mutations = {
  SET_ALL_LIST (state, notificacoes) {
    state.allLista = notificacoes
  },
  SET_NOTIFICACOES(state, notificacoes) {
      state.all = notificacoes
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
