/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const NotificacaoSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['*']
    filtro.addOrder('created_at', 'asc')
  return filtro
}
