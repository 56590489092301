/* eslint-disable */
import {lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getRequisicaoSCH, RequisicaoSCH} from "../../../search/RequisicaoSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Requisicao} from "../../../models/movimentacao/requisicao";

const url = '/requisicao'

const state = {
  all: [],
  requisicao: new Requisicao(),
  ListAll: [],
  filtro: new Filtro()
}

const getters = {
  listaRequisicoes: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['requisicoes.tp_mov', 'requisicoes.qtd_mov', 'produtos.descricao_site', 'fornecedores.nome'],
      '%' + search + '%', 'ilike')
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RequisicaoSCH(perPage))
  },

  async getItem({commit}, id) {
    var filtro = getRequisicaoSCH('requisicao_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    EventBus.$emit('GET_REQUISICAO_CONCLUIDO', true, list.data[0])
  },

  async setAllListRequisicoes({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_REQUISICAO', list)
  },
  async setAllRequisicoes({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_REQUISICOES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarRequisicao({commit}, {requisicao, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, requisicao, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_REQUISICAO(state, requisicaos) {
    state.ListAll = requisicaos
  },
  SET_REQUISICOES(state, requisicaos) {
    state.all = requisicaos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_REQUISICAO(state, filtro) {
    state.requisicao = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
