/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class PedidoStatus {
  constructor (pedido_id) {
    this.pedido_status_id = uuidV4()
    this.pedido_id = pedido_id
    this.status = '0'
    this.dt_status = ''
    // this.motivo = ''
  }
}
