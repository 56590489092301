/* eslint-disable */
import {lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {FornecedorSCH, getFornecedorSCH} from "../../../search/FornecedorSCH"
import {Fornecedor} from '../../../models/fornecedor'
import {EventBus} from '../../../helpers/event-bus'

const url = '/fornecedor'

const state = {
  all: [],
  allLista: [],
  fornecedor: new Fornecedor(),
  filtro: new Filtro()
}

const getters = {
  listaFornecedores: (state, getters, rootState) => {
    return state.all
  },
  listaAllFornecedores: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  pegaFornecedor: (state) => {
    return state.fornecedor
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListFornecedores({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('FORNECEDORES_ALL_CONCLUIDO', true)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      '&unaccent(fornecedores.nome)',
      '&unaccent(fornecedores.bairro)',
      '&unaccent(municipios.descricao)'],
      "&'%' || unaccent('"+search+"') || '%'", 'ilike')

  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try{
      var filtro = getFornecedorSCH('fornecedor_id', id, '=')
      const list = (await pegaLista(url, filtro)).data
      commit('SET_FORNECEDOR', list.data[0])
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', FornecedorSCH(perPage))
  },
  async setAllFornecedores({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_FORNECEDORES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  setFornecedor({commit}, fornecedor) {
    commit('SET_FORNECEDOR', fornecedor)
  },
  async processarFornecedor({commit}, {fornecedor, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, fornecedor, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
        onSucesso()
      state.fornecedor = new Fornecedor()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
        onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST(state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_FORNECEDORES(state, fornecedor) {
    state.all = fornecedor
  },
  SET_FORNECEDOR(state, fornecedor) {
    state.fornecedor = fornecedor
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
