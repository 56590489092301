/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Subgrupo {
  constructor () {
    this.subgrupo_id = uuidV4()
    this.grupo_id = ''
    this.descricao = ''
    this.bloqueado = false
    this.imagens = []
  }
}
