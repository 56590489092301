/* eslint-disable */
import {filtrando, lista, pegaLista, processar} from '../../../services/GenericAPI'
import {Filtro, Where} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH"
import {DioceseSCH} from "../../../search/DioceseSCH"
import {EventBus} from '../../../helpers/event-bus'
import {Diocese} from '../../../models/diocese'

const url = '/diocese'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro()
}

const getters = {
  listaAllDioceses: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  listaDioceses: (state) => {
    return state.all
  },
  pegaDioceseId: (state) => (id) => {
    let dio = state.all.find(diocese => diocese.diocese_id === id)
    return dio !== null ? dio.descricao : '** NÃO ENCONTRADA'
  },
  pegaDiocese: (state) => (id) => {
    let dio = state.allLista.find(diocese => diocese.diocese_id === id)
    return dio !== null ? dio : new Diocese()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListDiocese({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('DIOCESES_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch([
      '&unaccent(dioceses.descricao)'
    ], "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('diocese_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
     commit('SET_DIOCESES', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', DioceseSCH(perPage))
  },
  // Pag
  async filtarDiocese({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_DIOCESES', list2)
  },

  async setAllDioceses({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      commit('SET_DIOCESES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async processarDiocese({commit}, {diocese, acao, onSucesso, onErro}) {
    try{
      let res = await processar(url, diocese, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    }catch(error){
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  async deletaDiocese({commit}, {diocese, acao}){
      try{
        let res = await processar(url, diocese, acao)
        this.dispatch('geral/setMsgSuccess', res.data.message)
        this.dispatch('diocese/setAllDioceses')
      }catch(error){
        this.dispatch('geral/setObjMsgError', error.response.data)
      }
  }
}

const mutations = {
  SET_ALL_LIST (state, dioceses) {
    state.allLista = dioceses
  },
  SET_DIOCESES(state, dioceses) {
      state.all = dioceses
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
